/*
* 업무구분: 신탁>고객/상품>대리인등록>신탁계약대리인/지급청구대리인등록
* 화 면 명: MSPTS106M
* 화면설명: 신탁계약대리인/지급청구대리인등록
* 작 성 일: 2023.04.19
* 작 성 자: 송진의
* 수 정 일: 2024-07-30 정수진 사유 : ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응
* 수 정 일: 2024-08-26 정수진 사유 : ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="신탁계약대리인" :topButton="true" v-if="lv_isMain">
    <!-- header start -->
    <ts-header ref="tsHeader" :propObj="pHeaderObj"></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <mo-decimal-field
                    numeric
                    ref="tac_no"
                    class="w130"
                    :class="lv_tac_no.error ? 'error' : ''"
                    v-model="lv_tac_no.value"
                    :disabled="lv_tac_no.disabled"
                    @keyup="fn_AccnoSearch"
                    mask="#######"
                  />
                  <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('00')"> </mo-button>
                  <mo-text-field class="w130" v-model="lv_cnm" disabled />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w80p">
                <div class="wrap-input row mr-3">
                  <label class="emphasis"> 통장번호 </label>
                  <div class="wrap-input row">
                    <mo-dropdown class="w200" ref="bnkb_seq" :items="lv_bnkb_seq_items" v-model="lv_bnkb_seq.value" :disabled="lv_bnkb_seq.disabled" @input="fn_AgntCtNoSearch" placeholder=" " />
                  </div>
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 계약번호 </label>
                  <div class="wrap-input row">
                    <mo-dropdown class="w130" ref="ct_no" :items="lv_ct_no_items" v-model="lv_ct_no.value" :disabled="lv_ct_no.disabled" @input="fn_AgntContDtlSearch('S')" placeholder=" " />
                  </div>
                </div>
                <div class="wrap-input row">
                  <mo-radio v-model="lv_agntType" :disabled="lv_btn_agnt" value="agnt" @input="fn_AgntContDtlSearch('S')">대리인</mo-radio>
                  <mo-radio class="ml10" v-model="lv_agntType" :disabled="lv_btn_agnt" value="payClamAgnt" @input="fn_AgntContDtlSearch('S')">지급청구대리인</mo-radio>
                </div>
              </div>
              <div class="right w20p">
                <div class="wrap-button row">
                  <mo-button :disabled="lv_btn_init" @click="fn_Init()"> 초기화 </mo-button>
                  <mo-button primary class="inquiry" :disabled="lv_btn_search" @click="fn_AgntContDtlSearch('S')"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- 대리인정보 선택시 -->
      <ur-box-container direction="column" alignV="start" v-if="lv_agntType == 'agnt'">
        <div class="wrap-table-title row">
          <h2 class="table-title">대리인정보</h2>
          <div class="wrap-button result">
            <mo-button @click="fn_amlprocess()" :disabled="btnAml">
              AML
            </mo-button>
          </div>
          <!-- ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응 -->
          <!-- <mo-radio-wrapper :items="itemProfit" v-model="valueProfit" class="ml-5"/> -->
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th><span class="emphasis"> 대리인성명 </span></th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_agnt_info.agnt_nm.error ? 'error' : ''" v-model="lv_agnt_info.agnt_nm.value" :disabled="lv_agnt_info.agnt_nm.disabled" />
                    <mo-button class="btn-pop-download" :disabled="lv_agnt_info.btn_agnt_nm_search" @click="fn_OpenMSPTS107P('01', 'D')"> </mo-button>
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th><span class="emphasis"> 실명번호종류 </span></th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown ref="cob_rname_gb" :items="cmb_rcnoKindTc" v-model="lv_agnt_info.cob_rname_gb.value" :disabled="lv_agnt_info.cob_rname_gb.disabled" />
                  </div>
                </td>
                <th><span class="emphasis"> 실명번호 </span></th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="ed_rname_no"
                      class="input-long"
                      :class="lv_agnt_info.ed_rname_no.error ? 'error' : ''"
                      v-model="lv_agnt_info.ed_rname_no.value"
                      :disabled="lv_agnt_info.ed_rname_no.disabled"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="3"><span> 주소 </span></th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.zipNum" :disabled="lv_agnt_info.addr.disabled" />
                    <mo-button class="btn-input" :disabled="lv_agnt_info.btn_addr_search" @click="fn_OpenPopup(1)"> 우편번호 </mo-button>
                  </div>
                </td>
                <th><span class="emphasis"> 본인과의 관계 </span></th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="agnt_rltn"
                      class="input-long"
                      :class="lv_agnt_info.agnt_rltn.error ? 'error' : ''"
                      v-model="lv_agnt_info.agnt_rltn.value"
                      :disabled="lv_agnt_info.agnt_rltn.disabled"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.value1" :disabled="lv_agnt_info.addr.disabled" />
                    <mo-text-field class="w59" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.value2" :disabled="lv_agnt_info.addr.disabled" />
                  </div>
                </td>
                <th class="bl-ddd">전화번호</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="tel_no1"
                      class="input-long"
                      :class="lv_agnt_info.tel_no.error ? 'error' : ''"
                      v-model="lv_agnt_info.tel_no.value1"
                      :disabled="lv_agnt_info.tel_no.disabled"
                      maxlength="3"
                    />
                    <mo-text-field
                      ref="tel_no2"
                      class="input-long"
                      :class="lv_agnt_info.tel_no.error ? 'error' : ''"
                      v-model="lv_agnt_info.tel_no.value2"
                      :disabled="lv_agnt_info.tel_no.disabled"
                      maxlength="4"
                    />
                    <mo-text-field
                      ref="tel_no3"
                      class="input-long"
                      :class="lv_agnt_info.tel_no.error ? 'error' : ''"
                      v-model="lv_agnt_info.tel_no.value3"
                      :disabled="lv_agnt_info.tel_no.disabled"
                      maxlength="4"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.value3" :disabled="lv_agnt_info.addr.disabled" />
                  </div>
                </td>
                <th class="bl-ddd">휴대폰</th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :class="lv_agnt_info.phone_no.error ? 'error' : ''" :items="phoneNum" v-model="lv_agnt_info.phone_no.value1" :disabled="lv_agnt_info.phone_no.disabled" />
                    <mo-text-field
                      class="input-long"
                      :class="lv_agnt_info.phone_no.error ? 'error' : ''"
                      v-model="lv_agnt_info.phone_no.value2"
                      :disabled="lv_agnt_info.phone_no.disabled"
                      maxlength="4"
                    />
                    <mo-text-field
                      class="input-long"
                      :class="lv_agnt_info.phone_no.error ? 'error' : ''"
                      v-model="lv_agnt_info.phone_no.value3"
                      :disabled="lv_agnt_info.phone_no.disabled"
                      maxlength="4"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th><span class="emphasis"> 위임기간 </span></th>
                <td colspan="3">
                  <ul class="wrap-input row gap30">
                    <li class="row">
                      <mo-radio v-model="lv_agnt_info.rdo_athz_dt.value" :disabled="lv_agnt_info.rdo_athz_dt.disabled" value="dt_type_1" @input="fn_SetAthzDt(0)"></mo-radio>
                      <mo-date-picker
                        class="w130 ml6"
                        :class="lv_agnt_info.cal_athz_dt.error ? 'error' : ''"
                        v-model="lv_agnt_info.cal_athz_dt.value"
                        :disabled="lv_agnt_info.cal_athz_dt.disabled"
                        :bottom="false"
                        @input="fn_SetAthzDt(1)"
                      />
                    </li>
                    <li class="row">
                      <mo-radio v-model="lv_agnt_info.rdo_athz_dt.value" :disabled="lv_agnt_info.rdo_athz_dt.disabled" value="dt_type_2" @input="fn_SetAthzDt(0)">1년</mo-radio>
                      <mo-date-picker
                        class="w130 ml-2"
                        :class="lv_agnt_info.cal_athz_dt_yr.error ? 'error' : ''"
                        v-model="lv_agnt_info.cal_athz_dt_yr.value"
                        :disabled="lv_agnt_info.cal_athz_dt_yr.disabled"
                        :bottom="false"
                        @input="fn_SetAthzDt(1)"
                      />
                    </li>
                    <li class="row">
                      <mo-radio v-model="lv_agnt_info.rdo_athz_dt.value" value="dt_type_3" :disabled="lv_agnt_info.rdo_athz_dt.disabled" @input="fn_SetAthzDt(0)">일회성</mo-radio>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <!-- 위임내용 -->
      <ur-box-container direction="column" alignV="start" v-if="lv_agntType == 'agnt'">
        <div class="wrap-table-title row">
          <h2 class="table-title">위임내용</h2>
          <!-- <mo-radio-wrapper :items="itemProfit" v-model="valueProfit" class="ml-5"/> -->
        </div>
        <div class="wrap_box">
          <ul class="wrap-checklist2">
            <li><mo-checkbox :disabled="lv_agnt_info.chk_cont.disabled" v-model="lv_agnt_info.chk_cont.isAll" @click="fn_SetChkAll"> 모두 </mo-checkbox></li>
            <li><mo-checkbox :disabled="lv_agnt_info.chk_cont.disabled" v-model="lv_agnt_info.chk_cont.value" value="addIamt"> 추가입금 </mo-checkbox></li>
            <li><mo-checkbox :disabled="lv_agnt_info.chk_cont.disabled" v-model="lv_agnt_info.chk_cont.value" value="contChng"> 신탁계약변경 </mo-checkbox></li>
            <li><mo-checkbox :disabled="lv_agnt_info.chk_cont.disabled" v-model="lv_agnt_info.chk_cont.value" value="contTmtl"> 신탁계약해지 </mo-checkbox></li>
          </ul>
        </div>
      </ur-box-container>

      <ur-box-container v-if="lv_agntType == 'agnt'">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Cancle()" :disabled="lv_agnt_info.btn_cancle">취소</mo-button>
          <mo-button point size="large" @click="fn_OpenModal('Del')" :disabled="lv_agnt_info.btn_delete">삭제</mo-button>
          <mo-button point size="large" @click="fn_Update()" :disabled="lv_agnt_info.btn_update">수정</mo-button>
          <mo-button point size="large" @click="fn_Insert()" :disabled="lv_agnt_info.btn_insert">입력</mo-button>
          <mo-button primary size="large" @click="fn_Confirm()" :disabled="lv_agnt_info.btn_confirm"> 확인 </mo-button>
        </div>
      </ur-box-container>

      <!-- 지급청구대리인 선택시 -->
      <div v-for="(info, idx) in lv_pay_info_list" :key="idx">
        <ur-box-container direction="column" alignV="start" v-if="lv_agntType == 'payClamAgnt'">
          <div class="wrap-table-title row">
            <h2 class="table-title">지급청구대리인({{ idx === 0 ? '1' : '2' }}차) 정보</h2>
            <div class="wrap-button result">
              <mo-button @click="fn_amlprocess()" :disabled="btnAml">
                AML
              </mo-button>
            </div>
            <!-- ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응 -->
          </div>
          <div class="wrap-table">
            <table class="table col-type w50p col2030">
              <tbody>
                <tr>
                  <th><span class="emphasis"> 지급청구대리인성명 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="info.agnt_nm.error ? 'error' : ''" v-model="info.agnt_nm.value" :disabled="info.agnt_nm.disabled" />
                      <mo-button class="btn-pop-download" :disabled="info.btn_agnt_nm_search" @click="fn_OpenMSPTS107P('01', info.agnt_ts_cd)"> </mo-button>
                    </div>
                  </td>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <th><span class="emphasis"> 실명번호종류 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown ref="cob_rname_gb" :items="cmb_rcnoKindTc" v-model="info.cob_rname_gb.value" :disabled="info.cob_rname_gb.disabled" />
                    </div>
                  </td>
                  <th><span class="emphasis"> 실명번호 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field ref="ed_rname_no" class="input-long" :class="info.ed_rname_no.error ? 'error' : ''" v-model="info.ed_rname_no.value" :disabled="info.ed_rname_no.disabled" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th rowspan="3"><span> 주소 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="info.addr.error ? 'error' : ''" v-model="info.addr.zipNum" :disabled="info.addr.disabled" />
                      <mo-button class="btn-input" :disabled="info.btn_addr_search" @click="fn_OpenPopup(1)"> 우편번호 </mo-button>
                    </div>
                  </td>
                  <th><span class="emphasis"> 본인과의 관계 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown
                        :class="info.agnt_rltn.error ? 'error' : ''"
                        :items="cmb_agntRltnCd"
                        :itemValue="'text'"
                        :itemText="'text'"
                        v-model="info.agnt_rltn.value"
                        :disabled="info.agnt_rltn.disabled"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="info.addr.error ? 'error' : ''" v-model="info.addr.value1" :disabled="info.addr.disabled" />
                      <mo-text-field class="w59" :class="info.addr.error ? 'error' : ''" v-model="info.addr.value2" :disabled="info.addr.disabled" />
                    </div>
                  </td>
                  <th class="bl-ddd">전화번호</th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field ref="tel_no1" class="input-long" :class="info.tel_no.error ? 'error' : ''" v-model="info.tel_no.value1" :disabled="info.tel_no.disabled" maxlength="3" />
                      <mo-text-field ref="tel_no2" class="input-long" :class="info.tel_no.error ? 'error' : ''" v-model="info.tel_no.value2" :disabled="info.tel_no.disabled" maxlength="4" />
                      <mo-text-field ref="tel_no3" class="input-long" :class="info.tel_no.error ? 'error' : ''" v-model="info.tel_no.value3" :disabled="info.tel_no.disabled" maxlength="4" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="info.addr.error ? 'error' : ''" v-model="info.addr.value3" :disabled="info.addr.disabled" />
                    </div>
                  </td>
                  <th class="bl-ddd">휴대폰</th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown :class="info.phone_no.error ? 'error' : ''" :items="phoneNum" v-model="info.phone_no.value1" :disabled="info.phone_no.disabled" />
                      <mo-text-field class="input-long" :class="info.phone_no.error ? 'error' : ''" v-model="info.phone_no.value2" :disabled="info.phone_no.disabled" maxlength="4" />
                      <mo-text-field class="input-long" :class="info.phone_no.error ? 'error' : ''" v-model="info.phone_no.value3" :disabled="info.phone_no.disabled" maxlength="4" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th><span class="emphasis"> 위임기간 </span></th>
                  <td colspan="3">
                    <ul class="wrap-input row gap30">
                      <li class="row">
                        <mo-date-picker
                          class="w130"
                          :class="info.cal_athz_dt.error ? 'error' : ''"
                          v-model="info.cal_athz_dt.value"
                          :disabled="info.cal_athz_dt.disabled"
                          :bottom="false"
                          @input="fn_SetAthzDt(1, info)"
                        />
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ur-box-container>
        <ur-box-container v-if="lv_agntType == 'payClamAgnt'">
          <div class="wrap-button button-page-bottom row">
            <mo-button point size="large" @click="fn_Cancle()" :disabled="info.btn_cancle || idx === 0">취소</mo-button>
            <mo-button point size="large" @click="fn_OpenModal('Del', info.agnt_ts_cd)" :disabled="info.btn_delete || idx > 0">삭제</mo-button>
            <mo-button point size="large" @click="fn_Update(info)" :disabled="info.btn_update || idx > 0">수정</mo-button>
            <mo-button point size="large" @click="fn_Insert(info)" :disabled="info.btn_insert || idx > 0">입력</mo-button>
            <mo-button primary size="large" @click="fn_Confirm(info)" :disabled="info.btn_confirm || idx > 0"> 확인 </mo-button>
          </div>
        </ur-box-container>
      </div>
    </div>
    <!-- //.fts-main -->

    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modalDel">
      <template>
        <p class="lh14">
          선택한 항목을 삭제하시겠습니까?
        </p>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_ConfirmAlert('D', 'N')">취소</mo-button>
          <mo-button primary size="large" @click="fn_ConfirmAlert('D', 'Y')">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modalTmp">
      <template>
        <p class="lh14">
          임시 데이터로 조회 하시겠습니까?
        </p>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_ConfirmAlert('T', 'N')">취소</mo-button>
          <mo-button primary size="large" @click="fn_ConfirmAlert('T', 'Y')">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modal1">
      <template>
        <p class="lh14">
          대리인등록이 완료되었습니다.
        </p>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="closeModal(1)">취소</mo-button>
          <mo-button primary size="large">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- alert modal popup -->
    <ts-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></ts-alert-popup>

    <!-- popup100 우편번호 -->
    <msp-fs-100p ref="popup100" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup100Obj"></msp-fs-100p>

    <!-- ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응 -->
    <msp-ts-303p ref="popup303" :popup303Obj="pPopup303Obj" @ts-popup303-callback="fn_popupAfter"></msp-ts-303p>

    <msp-ts-107p ref="popup107" :popup107Obj="pPopup107Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup107-callback="fn_Popup107Back"></msp-ts-107p>
  </ur-page-container>
  <ur-box-container v-else-if="!lv_isMain">
    <!-- popup-->
    <mo-modal class="fts-modal modal-full" ref="modalAll" :title="pHeaderObj.title" full-screen no-foot>
      <template>
        <ur-box-container direction="column" alignV="start">
          <div class="input-top">
            <div class="column w100p gap12">
              <div class="row w100p">
                <div class="left">
                  <div class="wrap-input row">
                    <label class="emphasis"> 계좌번호 </label>
                    <mo-text-field ref="tac_no" class="w130" :class="lv_tac_no.error ? 'error' : ''" v-model="lv_tac_no.value" :disabled="lv_tac_no.disabled" maxlength="7" @keyup="fn_AccnoSearch" />
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('00')"> </mo-button>
                    <mo-text-field class="w130" v-model="lv_cnm" disabled />
                  </div>
                </div>
              </div>
              <div class="row w100p">
                <div class="left w80p">
                  <div class="wrap-input row mr-3">
                    <label class="emphasis"> 통장번호 </label>
                    <div class="wrap-input row">
                      <mo-dropdown class="w200" ref="bnkb_seq" :items="lv_bnkb_seq_items" v-model="lv_bnkb_seq.value" :disabled="lv_bnkb_seq.disabled" @input="fn_AgntCtNoSearch" placeholder=" " />
                    </div>
                  </div>
                  <div class="wrap-input row">
                    <label class="emphasis"> 계약번호 </label>
                    <div class="wrap-input row">
                      <mo-dropdown class="w130" ref="ct_no" :items="lv_ct_no_items" v-model="lv_ct_no.value" :disabled="lv_ct_no.disabled" @input="fn_AgntContDtlSearch('S')" placeholder=" " />
                    </div>
                  </div>
                  <div class="wrap-input row">
                    <mo-radio v-model="lv_agntType" :disabled="lv_btn_agnt" value="agnt" @input="fn_AgntContDtlSearch('S')">대리인</mo-radio>
                    <mo-radio class="ml10" v-model="lv_agntType" :disabled="lv_btn_agnt" value="payClamAgnt" @input="fn_AgntContDtlSearch('S')">지급청구대리인</mo-radio>
                  </div>
                </div>
                <div class="right w20p">
                  <div class="wrap-button row">
                    <mo-button :disabled="lv_btn_init" @click="fn_Init()"> 초기화 </mo-button>
                    <mo-button primary class="inquiry" :disabled="lv_btn_search" @click="fn_AgntContDtlSearch('S')"> 조회 </mo-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ur-box-container>

        <!-- 대리인정보 선택시 -->
        <ur-box-container direction="column" alignV="start" v-if="lv_agntType == 'agnt'">
          <div class="wrap-table-title row">
            <h2 class="table-title">대리인정보</h2>
            <div class="wrap-button result">
              <mo-button @click="fn_amlprocess()" :disabled="btnAml">
                AML
              </mo-button>
            </div>
            <!-- ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응 -->
            <!-- <mo-radio-wrapper :items="itemProfit" v-model="valueProfit" class="ml-5"/> -->
          </div>
          <div class="wrap-table">
            <table class="table col-type w50p col2030">
              <tbody>
                <tr>
                  <th><span class="emphasis"> 대리인성명 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="lv_agnt_info.agnt_nm.error ? 'error' : ''" v-model="lv_agnt_info.agnt_nm.value" :disabled="lv_agnt_info.agnt_nm.disabled" />
                      <mo-button class="btn-pop-download" :disabled="lv_agnt_info.btn_agnt_nm_search" @click="fn_OpenMSPTS107P('01', 'D')"> </mo-button>
                    </div>
                  </td>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <th><span class="emphasis"> 실명번호종류 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown ref="cob_rname_gb" :items="cmb_rcnoKindTc" v-model="lv_agnt_info.cob_rname_gb.value" :disabled="lv_agnt_info.cob_rname_gb.disabled" />
                    </div>
                  </td>
                  <th><span class="emphasis"> 실명번호 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field
                        ref="ed_rname_no"
                        class="input-long"
                        :class="lv_agnt_info.ed_rname_no.error ? 'error' : ''"
                        v-model="lv_agnt_info.ed_rname_no.value"
                        :disabled="lv_agnt_info.ed_rname_no.disabled"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th rowspan="3"><span> 주소 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.zipNum" :disabled="lv_agnt_info.addr.disabled" />
                      <mo-button class="btn-input" :disabled="lv_agnt_info.btn_addr_search" @click="fn_OpenPopup(1)"> 우편번호 </mo-button>
                    </div>
                  </td>
                  <th><span class="emphasis"> 본인과의 관계 </span></th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field
                        ref="agnt_rltn"
                        class="input-long"
                        :class="lv_agnt_info.agnt_rltn.error ? 'error' : ''"
                        v-model="lv_agnt_info.agnt_rltn.value"
                        :disabled="lv_agnt_info.agnt_rltn.disabled"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.value1" :disabled="lv_agnt_info.addr.disabled" />
                      <mo-text-field class="w59" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.value2" :disabled="lv_agnt_info.addr.disabled" />
                    </div>
                  </td>
                  <th class="bl-ddd">전화번호</th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field
                        ref="tel_no1"
                        class="input-long"
                        :class="lv_agnt_info.tel_no.error ? 'error' : ''"
                        v-model="lv_agnt_info.tel_no.value1"
                        :disabled="lv_agnt_info.tel_no.disabled"
                        maxlength="3"
                      />
                      <mo-text-field
                        ref="tel_no2"
                        class="input-long"
                        :class="lv_agnt_info.tel_no.error ? 'error' : ''"
                        v-model="lv_agnt_info.tel_no.value2"
                        :disabled="lv_agnt_info.tel_no.disabled"
                        maxlength="4"
                      />
                      <mo-text-field
                        ref="tel_no3"
                        class="input-long"
                        :class="lv_agnt_info.tel_no.error ? 'error' : ''"
                        v-model="lv_agnt_info.tel_no.value3"
                        :disabled="lv_agnt_info.tel_no.disabled"
                        maxlength="4"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field class="input-long" :class="lv_agnt_info.addr.error ? 'error' : ''" v-model="lv_agnt_info.addr.value3" :disabled="lv_agnt_info.addr.disabled" />
                    </div>
                  </td>
                  <th class="bl-ddd">휴대폰</th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown :class="lv_agnt_info.phone_no.error ? 'error' : ''" :items="phoneNum" v-model="lv_agnt_info.phone_no.value1" :disabled="lv_agnt_info.phone_no.disabled" />
                      <mo-text-field
                        class="input-long"
                        :class="lv_agnt_info.phone_no.error ? 'error' : ''"
                        v-model="lv_agnt_info.phone_no.value2"
                        :disabled="lv_agnt_info.phone_no.disabled"
                        maxlength="4"
                      />
                      <mo-text-field
                        class="input-long"
                        :class="lv_agnt_info.phone_no.error ? 'error' : ''"
                        v-model="lv_agnt_info.phone_no.value3"
                        :disabled="lv_agnt_info.phone_no.disabled"
                        maxlength="4"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th><span class="emphasis"> 위임기간 </span></th>
                  <td colspan="3">
                    <ul class="wrap-input row gap30">
                      <li class="row">
                        <mo-radio v-model="lv_agnt_info.rdo_athz_dt.value" :disabled="lv_agnt_info.rdo_athz_dt.disabled" value="dt_type_1" @input="fn_SetAthzDt(0)"></mo-radio>
                        <mo-date-picker
                          class="w130 ml6"
                          :class="lv_agnt_info.cal_athz_dt.error ? 'error' : ''"
                          v-model="lv_agnt_info.cal_athz_dt.value"
                          :disabled="lv_agnt_info.cal_athz_dt.disabled"
                          :bottom="false"
                          @input="fn_SetAthzDt(1)"
                        />
                      </li>
                      <li class="row">
                        <mo-radio v-model="lv_agnt_info.rdo_athz_dt.value" :disabled="lv_agnt_info.rdo_athz_dt.disabled" value="dt_type_2" @input="fn_SetAthzDt(0)">1년</mo-radio>
                        <mo-date-picker
                          class="w130 ml-2"
                          :class="lv_agnt_info.cal_athz_dt_yr.error ? 'error' : ''"
                          v-model="lv_agnt_info.cal_athz_dt_yr.value"
                          :disabled="lv_agnt_info.cal_athz_dt_yr.disabled"
                          :bottom="false"
                          @input="fn_SetAthzDt(1)"
                        />
                      </li>
                      <li class="row">
                        <mo-radio v-model="lv_agnt_info.rdo_athz_dt.value" value="dt_type_3" :disabled="lv_agnt_info.rdo_athz_dt.disabled" @input="fn_SetAthzDt(0)">일회성</mo-radio>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 위임내용 -->
          <div class="wrap-table-title row">
            <h2 class="table-title">위임내용</h2>
            <!-- <mo-radio-wrapper :items="itemProfit" v-model="valueProfit" class="ml-5"/> -->
          </div>
          <div class="wrap_box">
            <ul class="wrap-checklist2">
              <li><mo-checkbox v-model="lv_agnt_info.chk_cont.isAll" @click="fn_SetChkAll"> 모두 </mo-checkbox></li>
              <li><mo-checkbox v-model="lv_agnt_info.chk_cont.value" value="addIamt"> 추가입금 </mo-checkbox></li>
              <li><mo-checkbox v-model="lv_agnt_info.chk_cont.value" value="contChng"> 신탁계약변경 </mo-checkbox></li>
              <li><mo-checkbox v-model="lv_agnt_info.chk_cont.value" value="contTmtl"> 신탁계약해지 </mo-checkbox></li>
            </ul>
          </div>
        </ur-box-container>

        <ur-box-container v-if="lv_agntType == 'agnt'">
          <div class="wrap-button button-page-bottom row">
            <div class="left">
              <mo-button point size="large" @click="fn_CloseModal('All')"> 닫기 </mo-button>
            </div>
            <mo-button point size="large" @click="fn_Cancle()" :disabled="lv_agnt_info.btn_cancle">취소</mo-button>
            <mo-button point size="large" @click="fn_OpenModal('Del')" :disabled="lv_agnt_info.btn_delete">삭제</mo-button>
            <mo-button point size="large" @click="fn_Update()" :disabled="lv_agnt_info.btn_update">수정</mo-button>
            <mo-button point size="large" @click="fn_Insert()" :disabled="lv_agnt_info.btn_insert">입력</mo-button>
            <mo-button primary size="large" @click="fn_Confirm()" :disabled="lv_agnt_info.btn_confirm"> 확인 </mo-button>
          </div>
        </ur-box-container>

        <!-- 지급청구대리인 선택시 -->
        <div v-for="(info, idx) in lv_pay_info_list" :key="idx">
          <ur-box-container direction="column" alignV="start" v-if="lv_agntType == 'payClamAgnt'">
            <div class="wrap-table-title row">
              <h2 class="table-title">지급청구대리인({{ idx === 0 ? '1' : '2' }}차) 정보</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type w50p col2030">
                <tbody>
                  <tr>
                    <th><span class="emphasis"> 지급청구대리인성명 </span></th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field class="input-long" :class="info.agnt_nm.error ? 'error' : ''" v-model="info.agnt_nm.value" :disabled="info.agnt_nm.disabled" />
                        <mo-button class="btn-pop-download" :disabled="info.btn_agnt_nm_search" @click="fn_OpenMSPTS107P('01', info.agnt_ts_cd)"> </mo-button>
                      </div>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <th><span class="emphasis"> 실명번호종류 </span></th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown ref="cob_rname_gb" :items="cmb_rcnoKindTc" v-model="info.cob_rname_gb.value" :disabled="info.cob_rname_gb.disabled" />
                      </div>
                    </td>
                    <th><span class="emphasis"> 실명번호 </span></th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field ref="ed_rname_no" class="input-long" :class="info.ed_rname_no.error ? 'error' : ''" v-model="info.ed_rname_no.value" :disabled="info.ed_rname_no.disabled" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th rowspan="3"><span> 주소 </span></th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field class="input-long" :class="info.addr.error ? 'error' : ''" v-model="info.addr.zipNum" :disabled="info.addr.disabled" />
                        <mo-button class="btn-input" :disabled="info.btn_addr_search" @click="fn_OpenPopup(1)"> 우편번호 </mo-button>
                      </div>
                    </td>
                    <th><span class="emphasis"> 본인과의 관계 </span></th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown
                          :class="info.agnt_rltn.error ? 'error' : ''"
                          :items="cmb_agntRltnCd"
                          :itemValue="'text'"
                          :itemText="'text'"
                          v-model="info.agnt_rltn.value"
                          :disabled="info.agnt_rltn.disabled"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field class="input-long" :class="info.addr.error ? 'error' : ''" v-model="info.addr.value1" :disabled="info.addr.disabled" />
                        <mo-text-field class="w59" :class="info.addr.error ? 'error' : ''" v-model="info.addr.value2" :disabled="info.addr.disabled" />
                      </div>
                    </td>
                    <th class="bl-ddd">전화번호</th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field ref="tel_no1" class="input-long" :class="info.tel_no.error ? 'error' : ''" v-model="info.tel_no.value1" :disabled="info.tel_no.disabled" maxlength="3" />
                        <mo-text-field ref="tel_no2" class="input-long" :class="info.tel_no.error ? 'error' : ''" v-model="info.tel_no.value2" :disabled="info.tel_no.disabled" maxlength="4" />
                        <mo-text-field ref="tel_no3" class="input-long" :class="info.tel_no.error ? 'error' : ''" v-model="info.tel_no.value3" :disabled="info.tel_no.disabled" maxlength="4" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field class="input-long" :class="info.addr.error ? 'error' : ''" v-model="info.addr.value3" :disabled="info.addr.disabled" />
                      </div>
                    </td>
                    <th class="bl-ddd">휴대폰</th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown :class="info.phone_no.error ? 'error' : ''" :items="phoneNum" v-model="info.phone_no.value1" :disabled="info.phone_no.disabled" />
                        <mo-text-field class="input-long" :class="info.phone_no.error ? 'error' : ''" v-model="info.phone_no.value2" :disabled="info.phone_no.disabled" maxlength="4" />
                        <mo-text-field class="input-long" :class="info.phone_no.error ? 'error' : ''" v-model="info.phone_no.value3" :disabled="info.phone_no.disabled" maxlength="4" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th><span class="emphasis"> 위임기간 </span></th>
                    <td colspan="3">
                      <ul class="wrap-input row gap30">
                        <li class="row">
                          <mo-date-picker
                            class="w130"
                            :class="info.cal_athz_dt.error ? 'error' : ''"
                            v-model="info.cal_athz_dt.value"
                            :disabled="info.cal_athz_dt.disabled"
                            :bottom="false"
                            @input="fn_SetAthzDt(1, info)"
                          />
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ur-box-container>
          <ur-box-container v-if="lv_agntType == 'payClamAgnt'">
            <div class="wrap-button button-page-bottom row">
              <div class="left">
                <mo-button v-if="idx > 0" point size="large" @click="fn_CloseModal('All')"> 닫기 </mo-button>
              </div>
              <mo-button point size="large" @click="fn_Cancle()" :disabled="info.btn_cancle || idx === 0">취소</mo-button>
              <mo-button point size="large" @click="fn_OpenModal('Del', info.agnt_ts_cd)" :disabled="info.btn_delete || idx > 0">삭제</mo-button>
              <mo-button point size="large" @click="fn_Update(info)" :disabled="info.btn_update || idx > 0">수정</mo-button>
              <mo-button point size="large" @click="fn_Insert(info)" :disabled="info.btn_insert || idx > 0">입력</mo-button>
              <mo-button primary size="large" @click="fn_Confirm(info)" :disabled="info.btn_confirm || idx > 0"> 확인 </mo-button>
            </div>
          </ur-box-container>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>
    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modalDel">
      <template>
        <p class="lh14">
          선택한 항목을 삭제하시겠습니까?
        </p>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_ConfirmAlert('D', 'N')">취소</mo-button>
          <mo-button primary size="large" @click="fn_ConfirmAlert('D', 'Y')">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modalTmp">
      <template>
        <p class="lh14">
          임시 데이터로 조회 하시겠습니까?
        </p>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_ConfirmAlert('T', 'N')">취소</mo-button>
          <mo-button primary size="large" @click="fn_ConfirmAlert('T', 'Y')">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modal1">
      <template>
        <p class="lh14">
          대리인등록이 완료되었습니다.
        </p>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="closeModal(1)">취소</mo-button>
          <mo-button primary size="large">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- alert modal popup -->
    <ts-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></ts-alert-popup>

    <!-- popup100 우편번호 -->
    <msp-fs-100p ref="popup100" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup100Obj"></msp-fs-100p>

    <!-- ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응 -->
    <msp-ts-303p ref="popup303" :popup303Obj="pPopup303Obj" @ts-popup303-callback="fn_popupAfter"></msp-ts-303p>

    <msp-ts-107p ref="popup107" :popup107Obj="pPopup107Obj" @ts-alert-popup="fn_AlertPopup" @ts-popup107-callback="fn_Popup107Back"></msp-ts-107p>
  </ur-box-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSDataSet from '~/src/ui/ts/dts/TSDataSet' // dataSet 정리
import TSHeader from '~/src/ui/ts/comm/TSHeader' // header 영역 (공통)
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup' // Alert 팝업 (공통)
import MSPFS100P from '~/src/ui/fs/MSPFS100P' // 우편번호 (공통)
import MSPTS107P from '~/src/ui/ts/MSPTS107P' //고객조회
import MSPTS303P from '@/ui/ts/MSPTS303P' //책임자승인요청 //ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응
import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil' // 신탁 공통 정보 유틸
import DateUtil from '~/src/ui/fs/comm_elst/exDateUtils'
// import MSPTS107P from '~/src/ui/ts/MSPTS107S' //고객정보 팝업 (임시)
import moment from 'moment'

/************************************************************************************************
 * DataSet 영역
 ************************************************************************************************/
const dsList20_b = {
  refNo: '',
  csId: '',
  amlRstC: '',
  tacno: '',
  bnkbSeq: '',
  ctno: '',
  procSeq: '',
  amlPrcsPfrmType: '',
  amlProcSeq: '',
  stateFlag: ''
}

const dsDetail_b = {
  amlProcSeq: '',
  stateFlag: ''
} // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

//window 객체
var thisWin = null

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPTS106M',
  screenId: 'MSPTS106M',
  components: {
    'ts-header': TSHeader,
    'ts-alert-popup': TSAlertPopup,
    'msp-fs-100p': MSPFS100P,
    'msp-ts-303p': MSPTS303P, // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응
    'msp-ts-107p': MSPTS107P
  },
  props: {
    popupObj: { type: Object, default: null }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    if (!TSCommUtil.gfn_isNull(this.$route.params.isMain)) {
      this.lv_isMain = this.$route.params.isMain
    }

    this.fn_SetCommCode()
    this.fn_Init()

    this.$nextTick(() => {
      if (!TSCommUtil.gfn_isNull(this.$route.params.tacno)) {
        // 계좌번호 설정
        this.lv_tac_no.value = this.$route.params.tacno
        this.fn_AccnoSearch()

        this.lv_tac_param.bnkbSeq = !TSCommUtil.gfn_isNull(this.$route.params.bnkbSeq) ? this.$route.params.bnkbSeq : ''
        this.lv_tac_param.accSeq = !TSCommUtil.gfn_isNull(this.$route.params.accSeq) ? this.$route.params.accSeq : ''
      }
    })
  },
  mounted() {
    this.$bizUtil.insSrnLog('MSPTS106M')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      sSvcID: '',
      sPopupId: '',
      amlProcSeq: '',
      sPopupId: '',
      procSeq: '',
      // 공통 객체
      pHeaderObj: {
        title: '신탁계약대리인/지급청구대리인등록'
      },
      pAlertPopupObj: {},
      lv_commCode: {},

      // 팝업 객체
      popup303: {},
      pPopup303Obj: {}, // AML
      pPopup100Obj: {}, // 우편번호
      pPopup107Obj: {}, // 고객조회

      procType: '1',

      lv_agntType: 'agnt',
      _P_PARAMS: null,

      // 조회조건
      lv_tac_no: {}, // 종합계좌번호
      lv_bnkb_seq: {}, // 통장일련번호
      lv_ct_no: {}, // 계약일련번호
      lv_cs_id: '', // 고객ID

      bnkbSeqItems: [],
      ctnoItems: [],

      // button
      lv_btn_search: true, // 조회
      lv_btn_init: true, // 초기화
      lv_btn_agnt: true, // 대리인 타입 선택

      lv_stateFlag: '', // 수정, 삭제, 취소
      lv_isInsert: true, // 등록/수정 여부 판단
      btnAml: true, // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

      // 기본정보
      lv_cust_nm: {}, // 고객명

      // 대리인정보
      lv_agnt_info: {
        agnt_type_cd: '', // 대리인 신탁유형
        agnt_ts_cd: '', // 대리인 신탁코드
        agnt_cs_id: '', // 대리인 고객Id
        agnt_nm: {}, // 대리인성명
        btn_agnt_nm_search: true, // 대리인성명 고객조회
        addr: {}, // 주소
        btn_addr_search: true, // 우편번호 버튼
        tel_no: {}, // 전화번호
        phone_no: {}, // 휴대폰번호
        cob_rname_gb: {}, // 실명번호 종류
        ed_rname_no: {}, // 실명번호
        cal_athz_dt: {}, // 위임기간
        cal_athz_dt_yr: {}, // 위임기간 (1년)
        agnt_rltn: {}, // 대리인 관계
        rdo_athz_dt: {}, // 위임기간 radio
        chk_cont: {},
        mn_cntc_plc_tc: '', // 주소 위치 구분 (1 : 집, 2 : 회사, 3: 집 외, 4: 기타)

        btn_cancle: true, // 취소
        btn_delete: true, // 삭제
        btn_update: true, // 수정
        btn_insert: true, // 입력
        btn_confirm: true, // 확인
        btnAml: true, // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

        stateFlag: '' // 현재 버튼 상태
      },

      lv_pay_info: {
        agnt_type_cd: '', // 대리인 신탁유형
        agnt_ts_cd: '', // 대리인 신탁코드
        agnt_cs_id: '', // 대리인 고객Id
        agnt_nm: {}, // 대리인성명
        btn_agnt_nm_search: true, // 대리인성명 고객조회
        addr: {}, // 주소
        btn_addr_search: true, // 우편번호 버튼
        tel_no: {}, // 전화번호
        phone_no: {}, // 휴대폰번호
        cob_rname_gb: {}, // 실명번호 종류
        ed_rname_no: {}, // 실명번호
        cal_athz_dt: {}, // 위임기간
        cal_athz_dt_yr: {}, // 위임기간 (1년)
        agnt_rltn: {}, // 대리인 관계
        rdo_athz_dt: {}, // 위임기간 radio
        chk_cont: {},
        mn_cntc_plc_tc: '', // 주소 위치 구분 (1 : 집, 2 : 회사, 3: 집 외, 4: 기타)

        btn_cancle: true, // 취소
        btn_delete: true, // 삭제
        btn_update: true, // 수정
        btn_insert: true, // 입력
        btn_confirm: true, // 확인
        btnAml: true, // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

        stateFlag: '' // 현재 버튼 상태
      },

      lv_pay_info_list: [], // 지급청구대리인정보 목록

      /**
       * 공통코드 selectbox data
       */
      cmb_rcnoKindTc: [], // 실명종류
      cmb_incMnTc: [], // 소득자구분
      cmb_lvlhTc: [], // 생계형구분
      cmb_insrJobTc: [], // 보험직업구분
      cmb_ioAmtTrPpsC: [], // 입출금 거래목적
      cmb_accOpenPpsC: [], // 계좌신규목적
      cmb_natnNatC: TSDataSet.cmb_natnNatC, // 국가코드
      cmb_agntRltnCd: [], // 대리인 관계코드

      lv_tfabnb_list: [], // 대리인 리스트 조회 응답값
      lv_tfac27000_list: [], // 계약번호 리스트 조회 응답값
      lv_tfcori_list: [], // 고객기본정보 응답값
      lv_tftarl_list: [], // AML정보 응답값

      lv_bnkb_seq_items: [], // 통장번호 목록
      lv_ct_no_items: [], // 계약번호 목록
      dsDetail: [],
      dsList20: [],

      pPopup107Type: '', // 고객정보 팝업 시 선택 타입
      pPopup303Type: '', // AML 팝업 시 선택 타입  // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

      gdC: '', // 신탁상품
      trstTypC: '', // 신탁유형 (008 : 유언대용신탁(금전), 009 : 유언대용신탁(유가증권), 010 : 유언대용신탁(부동산))
      agntTc: '', // 대리인유형 (G1: 대리인, D1 : 지급청구, D2 : 지급청구 )
      gdTypDtlC: '', // 상품유형
      amlProcSeq: '', // 처리일련번호
      procSeq: '', // aml일련번호
      stateFlag: '',

      // 고객 정보
      lv_cnm: '', // 고객명
      lv_rcno: '', // 고객 주민번호 앞자리
      lv_hpTno: '', // 고객 핸드폰 번호
      lv_athzDate: '', // 위임기간
      lv_prhsRel: '', // 본인과의 관계2
      lv_type: '',

      lv_isDmntDign: false, // 치매진단 여부 ( true : 치매진단 , false : 치매아님)

      // Contents Model
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

      // 사용자정보
      lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,

      // 리포트 데이터 전달
      lv_transaction: {},

      // 전달 파라미터
      lv_tac_param: {},
      lv_isMain: false
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    phoneNum() {
      let rtn = []
      rtn.push({ value: '', text: '' })
      rtn.push({ value: '010', text: '010' })
      rtn.push({ value: '02', text: '02' })
      rtn.push({ value: '070', text: '070' })
      return rtn
    },

    // modal
    modal1() {
      return this.$refs.modal1
    },
    modal2() {
      return this.$refs.modal2
    },
    modalAll() {
      return this.$refs.modalAll
    },
    modalDel() {
      return this.$refs.modalDel
    },
    modalTmp() {
      return this.$refs.modalTmp
    },
    lv_isProcess() {
      return this.getStore('tsStore').getters.getState.isProcess
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : 모달 일시에 전체 팝업 오픈
     ******************************************************************************/
    fn_Open() {
      if (!TSCommUtil.gfn_isNull(this.popupObj.params)) {
        this.lv_isMain = false
        //this.fn_SetCommCode()
        this.fn_Init()

        this.$nextTick(() => {
          if (!TSCommUtil.gfn_isNull(this.popupObj.params.tacno)) {
            this.lv_tac_no.value = this.popupObj.params.tacno
            this.lv_tac_param.ctno = !TSCommUtil.gfn_isNull(this.popupObj.params.ctno) ? this.popupObj.params.ctno : ''
            this.fn_AccnoSearch()

            this.lv_tac_param.bnkbSeq = !TSCommUtil.gfn_isNull(this.popupObj.params.bnkbSeq) ? this.popupObj.params.bnkbSeq : ''
            this.lv_tac_param.accSeq = !TSCommUtil.gfn_isNull(this.popupObj.params.accSeq) ? this.popupObj.params.accSeq : ''
          }
        })

        this.$refs.modalAll.open()
      }
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      if (!TSCommUtil.gfn_isNull(pPopupObj)) {
        TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_CommCodeCallBack
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      // 호출 전 설정

      //본인과의 관계, 실명종류 arr초기화
      this.cmb_rcnoKindTc = [...this.cmb_rcnoKindTc]
      this.cmb_agntRltnCd = [...this.cmb_agntRltnCd]

      this.cmb_rcnoKindTc.push({ value: '', text: '- 선택 -' })
      this.cmb_agntRltnCd.push({ value: '', text: '- 선택 -' })

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {
        TUKEMK: [
          { cId: '510402' }, // cmb_rcnoKindTc 실명종류
          { cId: '506002' }, // cmb_incMnTc 소득자구분
          { cId: '5063' }, // cmb_lvlhTc 생계형구분
          { cId: '5038' }, // cmb_insrJobTc 보험직업구분
          { cId: '5114' }, // cmb_ioAmtTrPpsC 입출금 거래목적
          { cId: '5115' }, // cmb_accOpenPpsC 계좌신규목적
          { cId: '2700' } // cmb_agntRltnCd 대리인 관계코드
        ]
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CommCodeCallBack, this.fn_error)
    },
    fn_CommCodeCallBack(res) {
      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index]
        switch (item.cid) {
          case '5104': // 510402
            this.cmb_rcnoKindTc.push({ value: item.c, text: item.cnm })
            break
          case '5060': // 506002
            this.cmb_incMnTc.push({ value: item.c, text: item.cnm })
            break
          case '5063':
            this.cmb_lvlhTc.push({ value: item.c, text: item.cnm })
            break
          case '5038':
            this.cmb_insrJobTc.push({ value: item.c, text: item.cnm })
            break
          case '5114':
            this.cmb_ioAmtTrPpsC.push({ value: item.c, text: item.cnm })
            break
          case '5115':
            this.cmb_accOpenPpsC.push({ value: item.c, text: item.cnm })
            break
          case '2700':
            this.cmb_agntRltnCd.push({ value: item.c, text: item.cnm })
            break
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init(type = 'init') {
      console.log('[MSPTS106M] fn_Init...')

      // 검색조건
      if (type === 'init') {
        this.lv_tac_no = TSCommUtil.gfn_dataSet(1, '', false, '', 'tac_no') // 종합계좌번호
      }
      this.lv_bnkb_seq = TSCommUtil.gfn_dataSet(1, '', false, '', 'bnkb_seq') // 통장일련번호
      this.lv_ct_no = TSCommUtil.gfn_dataSet(1, '', false, '', 'ct_no') // 계약일련번호

      // 검색 표시
      this.lv_cnm = '' // 계약자명
      this.lv_cs_id = ''

      this.lv_btn_search = true // 조회
      this.lv_btn_init = true // 초기화
      this.lv_btn_agnt = true // 대리인 선택
      this.lv_agntType = 'agnt'
      this.trstTypC = '' // 신탁유형 - 팝업 선택 시
      this.agntTc = '' // 대리인코드 - 팝업 선택 시
      this.lv_stateFlag = ''
      this.btnAml = true // AML // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

      // 버튼
      this.lv_bnkb_seq_items = []
      this.lv_ct_no_items = []
      this.lv_tfabnb_list = []

      this.fn_InitData('agnt')
      this.fn_InitData('pay')
    },

    /******************************************************************************
     * Function명 : fn_InitData
     * 설명       : 조회 전 데이터 초기화
     ******************************************************************************/
    fn_InitData(type = 'agnt') {
      if (type === 'agnt') {
        let tmp_agnt_info = {}
        tmp_agnt_info = this.fn_SetInitData(type, tmp_agnt_info)
        this.lv_agnt_info = Object.assign({}, tmp_agnt_info)
      } else {
        let tmp_agnt_info_1 = {}
        let tmp_agnt_info_2 = {}
        tmp_agnt_info_1 = this.fn_SetInitData(type, tmp_agnt_info_1)
        tmp_agnt_info_2 = this.fn_SetInitData(type, tmp_agnt_info_2)

        this.lv_pay_info_list = []
        this.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info_1))
        this.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info_2))
      }

      this.lv_isDmntDign = false
    },
    /******************************************************************************
     * Function명 : fn_InitCustData
     * 설명       : 고객 정보 조회 팝업 후 데이터 초기화
     ******************************************************************************/
    fn_InitCustData(type) {
      console.log('fn_InitCustData >', type)
      let tmp_agnt_info = {}
      switch (type) {
        case 'D':
        case 'D1':
          tmp_agnt_info = this.fn_SetInitData(type, tmp_agnt_info, true, true)
          this.lv_agnt_info = Object.assign({}, tmp_agnt_info)
          break
        case 'G':
        case 'G1':
          tmp_agnt_info = this.fn_SetInitData(type, this.lv_pay_info_list[0], true, true)
          this.lv_pay_info_list[0] = Object.assign({}, tmp_agnt_info)
          // this.lv_pay_info_list[1] = Object.assign({}, tmp_agnt_info)
          break
        case 'G2':
          tmp_agnt_info = this.fn_SetInitData(type, this.lv_pay_info_list[1], true, true)
          // this.lv_pay_info_list[0] = Object.assign({}, tmp_agnt_info)
          this.lv_pay_info_list[1] = Object.assign({}, tmp_agnt_info)
          break
      }
    },

    fn_SetInitData(type = 'agnt', ref_agnt_info, isFlag = true, isCustSearch = false) {
      let tmp_agnt_info = Object.assign({}, ref_agnt_info)
      if (!isCustSearch) {
        tmp_agnt_info.btn_confirm = true // 확인
        tmp_agnt_info.btn_insert = true // 입력
        tmp_agnt_info.btn_update = true // 수정
        tmp_agnt_info.btn_delete = true // 삭제
        tmp_agnt_info.btn_cancle = true // 취소
        tmp_agnt_info.btnAml = true // AML // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

        tmp_agnt_info.agnt_cs_id = '' // 대리인 고객 ID
        tmp_agnt_info.agnt_ts_cd = '' // 대리인 신탁 코드
        tmp_agnt_info.agnt_type_cd = '' // 대리인 신탁 유형
      }

      tmp_agnt_info.agnt_nm = TSCommUtil.gfn_dataSet(1, '', true, '', 'agnt_nm') // 대리인 성명
      tmp_agnt_info.cob_rname_gb = TSCommUtil.gfn_dataSet(1, '', true, '', 'rname_gb') // 실명번호 종류
      tmp_agnt_info.ed_rname_no = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'ed_rname_no') // 실명번호종류
      tmp_agnt_info.agnt_rltn = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'agnt_rltn') // 본인과의 관계
      tmp_agnt_info.btn_agnt_nm_search = true // 대리인성명 고객조회 버튼
      tmp_agnt_info.btn_addr_search = true // 우편번호 조회 버튼

      tmp_agnt_info.mn_cntc_plc_tc = '' // 주소 구분
      tmp_agnt_info.stateFlag = '' // 버튼 이벤트 상태

      // 주소
      let tmp_addr = {
        error: false,
        disabled: true,
        zipNum: '',
        standard: '',
        value1: '',
        value2: '',
        value3: '',
        value4: ''
      }

      // 전화번호
      let tmp_tel_no = {
        error: false,
        disabled: true,
        value1: '',
        value2: '',
        value3: ''
      }

      // 휴대폰번호
      let tmp_phone_no = {
        error: false,
        disabled: true,
        value1: '',
        value2: '',
        value3: ''
      }

      // 위임내용
      let tmp_chk_cont = {
        disabled: isFlag,
        value: [],
        isAll: false
      }

      tmp_agnt_info.addr = Object.assign({}, tmp_addr)
      tmp_agnt_info.tel_no = Object.assign({}, tmp_tel_no)
      tmp_agnt_info.phone_no = Object.assign({}, tmp_phone_no)

      tmp_agnt_info.cal_athz_dt = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'cal_athz_dt') // 위임기간
      if (type === 'agnt') {
        tmp_agnt_info.cal_athz_dt_yr = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'cal_athz_dt_yr') // 위임기간 (1년)
        tmp_agnt_info.rdo_athz_dt = TSCommUtil.gfn_dataSet(0, 'dt_type_1', isFlag, '', 'rdo_athz_dt') // 위임기간 (라디오)
        tmp_agnt_info.chk_cont = tmp_chk_cont
      }

      return tmp_agnt_info
    },

    /******************************************************************************
     * Function명 : fn_AccnoSearch
     * 설명       : 계좌번호조회
     ******************************************************************************/
    fn_AccnoSearch(event) {
      // console.log('fn_AccnoSearch.....')
      if (TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
        if (!TSCommUtil.gfn_isNum(this.lv_tac_no.value)) {
          TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
          this.lv_tac_no.error = true
          this.$refs['tac_no'].focus()
          return
        } else {
          this.lv_tac_no.error = false
        }

        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS20S3'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600215_S'
        this.eaiCommObj.params = {
          tacno: this.lv_tac_no.value
          // bnkbSeq: this.lv_bnkb_seq.value,
          // csId: this.lv_cs_id,
        }
        console.log('fn_AccnoSearch - eaiCommObj >', this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AccnoSearchResult)
      } else {
        this.fn_Init('keyup')
      }
    },

    /******************************************************************************
     * Function명 : fn_AccnoSearchResult
     * 설명       : 계좌번호조회 결과
     ******************************************************************************/
    fn_AccnoSearchResult(pResultData) {
      console.log('fn_AccnoSearchResult.....')

      let t_data = pResultData.data

      // console.log('fn_AccnoSearchResult - pResultData > ', pResultData)
      this.fn_Init('accno')

      //if (t_data !== null && t_data !== '' && t_data.tfabnb.length > 0) {
      if (t_data !== null && t_data !== '' && t_data.csId !== '') {
        this.lv_btn_search = false // 조회
        this.lv_btn_init = false // 초기화
        this.lv_btn_agnt = false // 대리인 선택
        // this.lv_tfabnb_list = t_data.tfabnb

        // 고객id 설정
        this.lv_cs_id = t_data.csId
        if (!this.$bizUtil.isEmpty(this.lv_cs_id)) {
          this.fn_CstBasInfoDtlSearch()
        }

        this.fn_BnkbSearch()
      } else if (!TSCommUtil.gfn_isNull(t_data.errorMsg)) {
        this.fn_AlertPopup(0, { content: t_data.errorMsg })
      }
    },

    /******************************************************************************
     * Function명 : fn_BnkbSearch
     * 설명       : 통장번호조회
     ******************************************************************************/
    fn_BnkbSearch() {
      // console.log('fn_AccnoSearch.....')

      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS20S3'
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600083_S'
      this.eaiCommObj.params = {
        tacno: this.lv_tac_no.value
        // bnkbSeq: this.lv_bnkb_seq.value,
        // csId: this.lv_cs_id,
      }
      console.log('fn_BnkbSearch - eaiCommObj >', this.eaiCommObj)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_BnkbSearchResult)
    },

    /******************************************************************************
     * Function명 : fn_BnkbSearchResult
     * 설명       : 통장번호조회 결과
     ******************************************************************************/
    fn_BnkbSearchResult(pResultData) {
      console.log('fn_BnkbSearchResult.....')

      let t_data = pResultData.data

      // console.log('fn_BnkbSearchResult - pResultData > ', pResultData)
      //this.fn_Init('bnkb')

      if (t_data !== null && t_data !== '' && t_data.tfabnb.length > 0) {
        // this.lv_btn_search = false // 조회
        // this.lv_btn_init   = false // 초기화
        // this.lv_btn_agnt   = false // 대리인 선택
        this.lv_tfabnb_list = t_data.tfabnb

        // 계좌번호 설정
        this.lv_tfabnb_list.forEach((item) => {
          this.lv_bnkb_seq_items.push({ value: item.bnkbSeq, text: item.tacnoBnkbSeq })
        })

        // // 고객id 설정
        // this.lv_cs_id = this.lv_tfabnb_list[0].csId
        // if (!this.$bizUtil.isEmpty(this.lv_cs_id)){
        //   this.fn_CstBasInfoDtlSearch()
        // }

        // 콤보박스 설정
        // 계좌번호
        if (!this.$bizUtil.isEmpty(this.lv_bnkb_seq_items) && this.lv_bnkb_seq_items.length > 0) {
          if (!TSCommUtil.gfn_isNull(this.lv_tac_param.bnkbSeq)) {
            this.lv_bnkb_seq.value = this.lv_tac_param.bnkbSeq
          } else {
            this.lv_bnkb_seq.value = this.lv_bnkb_seq_items[0].value
          }

          // 계약번호
          this.fn_AgntCtNoSearch()
        }
      } else {
      }
    },

    /******************************************************************************
     * Function명 : fn_AgntCtNoSearch
     * 설명       : 대리인계약번호조회(통장번호 콤보 변경 시)
     ******************************************************************************/
    fn_AgntCtNoSearch() {
      console.log('fn_AgntCtNoSearch.....')
      if (TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
        if (!TSCommUtil.gfn_isNum(this.lv_tac_no.value)) {
          TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
          this.lv_tac_no.error = true
          this.$refs['tac_no'].focus()
          return
        } else {
          this.lv_tac_no.error = false
        }

        // 비활성화

        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS27S2'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600112_S'
        this.eaiCommObj.params = {
          tacno: this.lv_tac_no.value,
          bnkbSeq: this.lv_bnkb_seq.value,
          pageRowCnt: 0,
          stndKeyId: ''
        }
        console.log('fn_AgntCtNoSearch - eaiCommObj >', this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AgntCtNoSearchResult)
      }
    },

    /******************************************************************************
     * Function명 : fn_AgntCtNoSearchResult
     * 설명       : 대리인계약번호조회 결과
     ******************************************************************************/
    fn_AgntCtNoSearchResult(pResultData) {
      console.log('fn_AgntCtNoSearchResult.....')

      let t_data = pResultData.data

      console.log('fn_AgntCtNoSearchResult - pResultData > ', pResultData)
      this.lv_ct_no_items = []
      this.lv_tfac27000_list = []
      if (t_data !== null && t_data !== '') {
        if (t_data.tfac27000.length > 0) {
          this.lv_btn_search = false
          this.lv_tfac27000_list = t_data.tfac27000
          this.lv_tfac27000_list.forEach((item) => {
            // 계좌번호 설정
            this.lv_ct_no_items.push({ value: item.ctno, text: item.ctno })
          })

          // 콤보박스 설정
          // 계약번호
          if (!this.$bizUtil.isEmpty(this.lv_ct_no_items) && this.lv_ct_no_items.length > 0) {
            if (!TSCommUtil.gfn_isNull(this.lv_tac_param.accSeq)) {
              this.lv_ct_no.value = this.lv_tac_param.accSeq
              this.fn_AgntContDtlSearch('S')
              this.lv_tac_param = Object.assign({})
            } else {
              this.lv_ct_no.value = this.lv_ct_no_items[0].value
            }
            this.lv_ct_no.disabled = false
          }
        } else if (t_data.tfac27000.length === 0) {
          this.lv_ct_no.disabled = true
        }
        //set ctno
        let ctno = this.lv_tac_param.ctno
        if (ctno != null && ctno != '') {
          this.lv_ct_no.value = ctno
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     * 파라미터   : 리턴 파라미터
     ******************************************************************************/
    async fn_ReportPrint(pParam, nextFunc) {
      console.log('fn_ReportPrint....')
      let formList = []
      if (TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
        if (!TSCommUtil.gfn_isNum(this.lv_tac_no.value)) {
          TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
          this.lv_tac_no.error = true
          this.$refs['tac_no'].focus()
          return
        } else {
          this.lv_tac_no.error = false
        }

        // 전자서명동의서
        let TS000001 = { formId: 'TS000001' }
        let searchData = {
          tacno: this.lv_tac_no.value, //계좌번호
          bnkbSeq: this.lv_bnkb_seq.value, //통장일련번호
          //accSeq: this.tacno
          ctno: this.lv_ct_no.value //계약번호
        }

        TS000001.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportTFAC20000'
        }
        formList.push(TS000001)

        let TS000042 = { formId: 'TS000042' }
        let tmpCustInfo = this.lv_transaction.tfccsb[0] // 고객 정보 (대리인정보 포함)
        let tmpAddr = ''
        let tmpHomeTno = ''
        let tmpHpTno = ''

        this.lv_transaction.tfcori.forEach((tmpAgntInfo) => {
          tmpAddr = ''
          tmpHomeTno = ''
          tmpHpTno = ''

          if (tmpCustInfo.mnCntcPlcTc === '1' || tmpCustInfo.mnCntcPlcTc === '3') {
            // 주연락처구분코드 (1: 자택, 2: 직장, 3: e-mail, etc: 기타)
            if (tmpCustInfo.homeTelArno) {
              tmpHomeTno += tmpCustInfo.homeTelArno // 자택전화지역번호
            }
            if (tmpCustInfo.homeTelPofn) {
              tmpHomeTno += '-' + tmpCustInfo.homeTelPofn // 자택전화국번
            }
            if (tmpCustInfo.homeTelSeq) {
              tmpHomeTno += '-' + tmpCustInfo.homeTelSeq // 자택전화일련번호
            }

            if (tmpCustInfo.homePsnoAddr) {
              tmpAddr += tmpCustInfo.homePsnoAddr
            }

            if (tmpCustInfo.homeAddrBldnNo) {
              tmpAddr += ' ' + tmpCustInfo.homeAddrBldnNo
            }

            if (tmpCustInfo.homeRmnAddr) {
              tmpAddr += ' ' + tmpCustInfo.homeRmnAddr
            }
          } else if (tmpCustInfo.mnCntcPlcTc === '2') {
            if (tmpCustInfo.offcTelArno) {
              tmpHomeTno += tmpCustInfo.offcTelArno // 직장전화지역번호
            }
            if (tmpCustInfo.offcTelPofn) {
              tmpHomeTno += '-' + tmpCustInfo.offcTelPofn // 직장전화국번
            }
            if (tmpCustInfo.offcTelSeq) {
              tmpHomeTno += '-' + tmpCustInfo.offcTelSeq // 직장전화일련번호
            }

            if (tmpCustInfo.offcPsnoAddr) {
              tmpAddr += tmpCustInfo.offcPsnoAddr
            }

            if (tmpCustInfo.offcAddrBldnNo) {
              tmpAddr += ' ' + tmpCustInfo.offcAddrBldnNo
            }

            if (tmpCustInfo.offcRmnAddr) {
              tmpAddr += ' ' + tmpCustInfo.offcRmnAddr
            }
          }

          if (tmpCustInfo.mvphBusiMnNo) {
            tmpHpTno = tmpCustInfo.mvphBusiMnNo
          }
          if (tmpCustInfo.mvphPofn) {
            tmpHpTno += '-' + tmpCustInfo.mvphPofn
          }
          if (tmpCustInfo.mvphSeq) {
            tmpHpTno += '-' + tmpCustInfo.mvphSeq
          }

          // 대리인 위임기간 설정
          let tmpYn4 = ''
          if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
            // 직접
            tmpYn4 = '1'
          } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
            // 1년
            tmpYn4 = '2'
          } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_3') {
            // 일회성 = 위임기간이 위임일~업무처리 당일
            tmpYn4 = '1'
            tmpAgntInfo.athzDate = DateUtil.fn_CurrentDate()
          }

          TS000042.mappingData = {
            cnm: this.lv_cnm,
            rcno: this.lv_rcno,
            hpTno: this.lv_hpTno,
            agntNm: tmpCustInfo.cnm,
            agntRcno: !TSCommUtil.gfn_isNull(tmpCustInfo.rcno) ? tmpCustInfo.rcno.substr(0, 6) : '',
            agntPrhsRel: tmpAgntInfo.prhsRel,
            agntHpTno: !TSCommUtil.gfn_isNull(tmpHpTno) ? tmpHpTno : tmpHomeTno,
            agntAddr: tmpAddr,
            chkYn1: tmpAgntInfo.trstAddIamtAthzYn,
            chkYn2: tmpAgntInfo.trstContChngAthzYn,
            chkYn3: tmpAgntInfo.trstContTmtlAthzYn,
            chkYn4: tmpYn4,
            athzDate: tmpAgntInfo.athzDate
          }

          formList.push(TS000042)
        })

        TSInfoUtil.commReportInfo(this, formList, nextFunc, true, 'MSPTS106M')
      }
    },

    /******************************************************************************
     * Function명 : fn_CstBasInfoDtlSearch
     * 설명       : 고객기본정보상세조회
     ******************************************************************************/
    fn_CstBasInfoDtlSearch() {
      console.log('fn_CstBasInfoDtlSearch.....')
      if (!this.$bizUtil.isEmpty(this.lv_cs_id)) {
        // 비활성화

        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS27S3'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600113_S'
        this.eaiCommObj.params = {
          agntCsId: this.lv_cs_id,
          pageRowCnt: 0,
          stndKeyId: ''
        }
        console.log('fn_CstBasInfoDtlSearch - eaiCommObj >', this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CstBasInfoDtlSearchResult)
      }
    },
    /******************************************************************************
     * Function명 : fn_CstBasInfoDtlSearchResult
     * 설명       : 고객기본정보상세조회 결과
     ******************************************************************************/
    fn_CstBasInfoDtlSearchResult(pResultData) {
      console.log('fn_CstBasInfoDtlSearchResult.....')

      let t_data = pResultData.data

      console.log('fn_CstBasInfoDtlSearchResult - pResultData > ', pResultData)

      if (t_data !== null && t_data !== '') {
        console.log('fn_CstBasInfoDtlSearchResult - t_data > ', t_data)
        console.log('fn_CstBasInfoDtlSearchResult - t_data.cnm > ', t_data.cnm)
        // 고객명 설정
        this.lv_cnm = t_data.cnm
        this.lv_rcno = t_data.rcno.substr(0, 6) // 고객 주민번호 앞자리
        let tmpHomeTno = ''
        let tmpHpTno = ''
        if (t_data.mnCntcPlcTc === '1' || t_data.mnCntcPlcTc === '3') {
          // 주연락처구분코드 (1: 자택, 2: 직장, 3: e-mail, etc: 기타)
          if (t_data.homeTelArno) {
            tmpHomeTno += t_data.homeTelArno // 자택전화지역번호
          }
          if (t_data.homeTelPofn) {
            tmpHomeTno += '-' + t_data.homeTelPofn // 자택전화국번
          }
          if (t_data.homeTelSeq) {
            tmpHomeTno += '-' + t_data.homeTelSeq // 자택전화일련번호
          }
        } else if (t_data.mnCntcPlcTc === '2') {
          if (t_data.offcTelArno) {
            tmpHomeTno += t_data.offcTelArno // 직장전화지역번호
          }
          if (t_data.offcTelPofn) {
            tmpHomeTno += '-' + t_data.offcTelPofn // 직장전화국번
          }
          if (t_data.offcTelSeq) {
            tmpHomeTno += '-' + t_data.offcTelSeq // 직장전화일련번호
          }
        }

        if (t_data.mvphBusiMnNo) {
          tmpHpTno = t_data.mvphBusiMnNo
        }
        if (t_data.mvphPofn) {
          tmpHpTno += '-' + t_data.mvphPofn
        }
        if (t_data.mvphSeq) {
          tmpHpTno += '-' + t_data.mvphSeq
        }

        this.lv_hpTno = !TSCommUtil.gfn_isNull(tmpHpTno) ? tmpHpTno : tmpHomeTno // 고객 핸드폰 번호
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCtno
     * 설명       : 계약번호 설정
     * 파라미터(len) : 계약번호 length
     ******************************************************************************/
    fn_SetCtno(len) {
      let ctNoItems = []
      if (!this.$bizUtil.isEmpty(len) && Number(len) > 0) {
        for (let i = 0; i < Number(len); i++) {
          ctNoItems.push({ value: i, text: String(i) })
        }
      }
      return ctNoItems
    },

    /******************************************************************************
     * Function명 : fn_AgntContDtlSearch
     * 설명       : 대리인상세조회 (selectTFAC27000)
     ******************************************************************************/
    fn_AgntContDtlSearch(state = '') {
      if (state === 'S') {
        this.lv_stateFlag = ''
      }

      console.log('fn_AgntContDtlSearch.....')
      if (TSCommUtil.gfn_length(this.lv_tac_no.value) != 7 && TSCommUtil.gfn_length(this.lv_bnkb_seq.value) != 3) {
        TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
        this.lv_tac_no.error = true
        this.$refs['tac_no'].focus()
        return
      } else {
        this.lv_tac_no.error = false
      }

      //계약번호 미생성 고객 validation
      if (TSCommUtil.gfn_length(this.lv_ct_no.value) < 1) {
        TSCommUtil.gfn_validate(this, '계약번호를 확인하십시오')
        return
      }

      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS27S1'
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600111_S'
      this.eaiCommObj.params = {
        tacno: this.lv_tac_no.value, //종합계좌번호
        bnkbSeq: this.lv_bnkb_seq.value, //통장일련번호
        ctno: this.lv_ct_no.value, //계약일련번호
        agntTc: this.lv_agntType === 'agnt' ? 'D' : 'G', //대리인 or 지급청구대리인
        agntCsId: '',
        pageRowCnt: '0',
        stndKeyId: '0'
      }
      console.log('fn_AgntContDtlSearch - eaiCommObj >', this.eaiCommObj)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AgntSearchResult)
    },

    /******************************************************************************
     * Function명 : fn_AgntSearchResult
     * 설명       : 대리인조회 결과 (selectTFAC27000)
     ******************************************************************************/
    fn_AgntSearchResult(pResultData) {
      console.log('fn_AgntSearchResult.....')

      let lv_Vm = this
      let t_data = pResultData.data
      if (t_data !== null && t_data !== '') {
        this.fn_InitData('agnt')
        this.fn_InitData('pay')
        console.log('fn_AgntSearchResult > ', this.lv_pay_info_list)
        // 조회 결과 데이터가 없을 때
        if (t_data.tfccsb.length === 0) {
          console.log('fn_AgntSearchResult >', 0)
          this.fn_AlertPopup(0, { content: '해당계약의 대리인이 없습니다. 대리인을 등록해 주세요.' })
          return false
        }

        for (let i = 0; i < t_data.tfccsb.length; i++) {
          let tfccsb = t_data.tfccsb[i] // ds_detail01 , 대리인 상세조회
          let tfcori = t_data.tfcori[i] // ds_detail , 대리인 테이블
          let tfaddi = t_data.tfaddi[i] // ds_tfaddiRst , 계좌_질병진단정보
          if (!this.$bizUtil.isEmpty(tfccsb) && !this.$bizUtil.isEmpty(tfcori)) {
            let tmp_agnt_info = {}
            tfcori.agntTc = TSCommUtil.gfn_trim(tfcori.agntTc)

            if (this.lv_agntType === 'agnt') {
              tmp_agnt_info = Object.assign({}, this.lv_agnt_info)
            } else {
              if (tfcori.agntTc === 'G1' || tfcori.agntTc === 'G') {
                tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[0])
              } else if (tfcori.agntTc === 'G2') {
                tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[1])
              }
            }

            tmp_agnt_info.stateFlag = this.stateFlag
            tmp_agnt_info.amlProcSeq = this.lv_amlProcSeq // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

            //(trstTypC 008 : 유언대용신탁(금전), 009 : 유언대용신탁(유가증권), 010 : 유언대용신탁(부동산))
            //if (tfcori.trstTypC !== '007' && this.lv_agntType === 'payClamAgnt') {
            if (tfcori.trstTypC !== '008' && tfcori.trstTypC !== '009' && tfcori.trstTypC !== '010' && this.lv_agntType === 'payClamAgnt') {
              let t_popupObj = {
                content: '지급청구대리인은 유언대용신탁 계약에 한하여 등록 가능합니다.',
                closeFunc: lv_Vm.fn_SetAgnt
              }
              this.fn_AlertPopup(0, t_popupObj)
              return false
            }

            // if (!this.$bizUtil.isEmpty(tfaddi) && !TSCommUtil.gfn_isNull(tfaddi.dmntDignYn) && tfaddi.dmntDignYn === 'Y') {
            //   if (this.lv_agntType === 'agnt') {
            //     this.fn_AlertPopup(0,{content: '치매진단 이후에는 대리인 지정 및 변경이 불가합니다.'})
            //   } else {
            //     this.fn_AlertPopup(0,{content: '치매진단 이후에는 지급청구대리인 지정 및 변경이 불가합니다.'})
            //   }
            //   this.lv_isDmntDign = true
            // }

            tmp_agnt_info.agnt_type_cd = tfcori.trstTypC // 대리인 신탁유형
            tmp_agnt_info.agnt_ts_cd = tfcori.agntTc // 대리인 신탁코드
            tmp_agnt_info.agnt_cs_id = tfcori.agntCsId // 대리인 고객 ID

            //if (!this.lv_isDmntDign && TSCommUtil.gfn_isNull(tfccsb.csId)) {
            if (TSCommUtil.gfn_isNull(tfccsb.csId)) {
              if (this.lv_agntType === 'agnt') {
                tmp_agnt_info.btn_insert = false
                this.lv_agnt_info = Object.assign({}, tmp_agnt_info)
                this.fn_AlertPopup(0, { content: '해당계약의 대리인이 없습니다. 대리인을 등록해 주세요.' })
                return false
              } else {
                tmp_agnt_info.btn_insert = false
                this.lv_pay_info_list[0] = Object.assign({}, tmp_agnt_info)
                this.fn_AlertPopup(0, { content: '해당계약의 지급청구대리인이 없습니다. 지급청구대리인을 등록해 주세요.' })
                return false
              }
              this.fn_SetNoData(tfcori.agntTc, tmp_agnt_info) // 데이터 없을 시 설정
              return false
            } else if (TSCommUtil.gfn_isNull(tfccsb.csId)) {
              if (this.lv_agntType === 'payClamAgnt') {
                if (tfcori.agntTc === 'G' || tfcori.agntTc === 'G1' || tfcori.agntTc === 'G2') {
                  tmp_agnt_info.btn_insert = false
                }
              }
            }

            console.log('fn_AgntSearchResult - tmp_agnt_info >', tmp_agnt_info)

            tmp_agnt_info.agnt_nm.value = tfccsb.cnm
            tmp_agnt_info.agnt_nm.disabled = true // 대리인성명

            if (!TSCommUtil.gfn_isNull(tfccsb.rcno)) {
              tmp_agnt_info.ed_rname_no.value = tfccsb.rcno.substr(0, 6) + '-' + tfccsb.rcno.substr(6, 1) + '******' // 실명번호 (마스킹 임시)
            }

            tmp_agnt_info.ed_rname_no.disabled = true

            tmp_agnt_info.phone_no.value1 = tfccsb.mvphBusiMnNo // 이동전화사업자번호
            tmp_agnt_info.phone_no.value2 = tfccsb.mvphPofn // 이동전화국번
            tmp_agnt_info.phone_no.value3 = tfccsb.mvphSeq // 이동전화일련번호
            tmp_agnt_info.phone_no.disabled = true

            tmp_agnt_info.cob_rname_gb.value = tfccsb.rcnoKindTc // 실명번호 종류
            tmp_agnt_info.cob_rname_gb.disabled = true
            tmp_agnt_info.mn_cntc_plc_tc = tfccsb.mnCntcPlcTc // 주소 구분

            if (tfccsb.mnCntcPlcTc === '1' || tfccsb.mnCntcPlcTc === '3') {
              // 주연락처구분코드 (1: 자택, 2: 직장, 3: e-mail, etc: 기타)
              tmp_agnt_info.addr.zipNum = tfccsb.hmadPsno1 + tfccsb.hmadPsno2 // 자택주소우편번호1 + 자택주소우편번호2
              tmp_agnt_info.addr.value1 = tfccsb.homePsnoAddr // 자택우편번호주소
              tmp_agnt_info.addr.value2 = tfccsb.homeAddrBldnNo // 자택주소건물번호
              tmp_agnt_info.addr.value3 = tfccsb.homeRmnAddr // 자택잔여주소
              tmp_agnt_info.addr.standard = tfccsb.hmadStdztYn // 자택주소표준화여부

              tmp_agnt_info.tel_no.value1 = tfccsb.homeTelArno // 자택전화지역번호
              tmp_agnt_info.tel_no.value2 = tfccsb.homeTelPofn // 자택전화국번
              tmp_agnt_info.tel_no.value3 = tfccsb.homeTelSeq // 자택전화일련번호
            } else if (tfccsb.mnCntcPlcTc === '2') {
              tmp_agnt_info.addr.zipNum = tfccsb.offcAddrPsno01 + tfccsb.offcAddrPsno02 // 직장주소우편번호01 + 직장주소우편번호02
              tmp_agnt_info.addr.value1 = tfccsb.offcPsnoAddr // 직장우편번호주소
              tmp_agnt_info.addr.value2 = tfccsb.offcAddrBldnNo // 직장주소건물번호
              tmp_agnt_info.addr.value3 = tfccsb.offcRmnAddr // 직장잔여주소
              tmp_agnt_info.addr.standard = tfccsb.offcStdztDtlad // 직장주소표준화여부

              tmp_agnt_info.tel_no.value1 = tfccsb.offcTelArno // 직장전화지역번호
              tmp_agnt_info.tel_no.value2 = tfccsb.offcTelPofn // 직장전화국번
              tmp_agnt_info.tel_no.value3 = tfccsb.offcTelSeq // 직장전화일련번호
            } else {
              tmp_agnt_info.addr.zipNum = tfccsb.etcAddrPsno1 + tfccsb.etcAddrPsno2 // 기타주소우편번호01 + 기타주소우편번호02
              tmp_agnt_info.addr.value1 = tfccsb.etcPsnoAddr // 기타우편번호주소
              tmp_agnt_info.addr.value2 = tfccsb.etcAddrBldnNo // 기타주소건물번호
              tmp_agnt_info.addr.value3 = tfccsb.etcRmnAddr // 기타잔여주소
              tmp_agnt_info.addr.standard = tfccsb.etcStdztDtlad // 기타주소표준화여부
            }

            tmp_agnt_info.addr.disabled = true
            tmp_agnt_info.tel_no.disabled = true

            if (this.lv_agntType === 'agnt') {
              // 대리인 일때
              tmp_agnt_info.agnt_rltn.value = tfcori.prhsRel // 본인과의 관계
              tmp_agnt_info.agnt_rltn.disabled = true

              tmp_agnt_info.cal_athz_dt.value = TSCommUtil.gfn_dateReplace(1, tfcori.athzDate) // 위임기간
              tmp_agnt_info.cal_athz_dt.disabled = true

              if (tfcori.athzDate === '00000000') {
                // 일회성
                tmp_agnt_info.cal_athz_dt.value = ''
                tmp_agnt_info.rdo_athz_dt.value = 'dt_type_3'
              } else {
                // 날짜
                tmp_agnt_info.rdo_athz_dt.value = 'dt_type_1'
              }

              // 위임내용 설정
              tmp_agnt_info.chk_cont.value = this.fn_SetTrstAthz(tfcori)

              //(trstTypC 008 : 유언대용신탁(금전), 009 : 유언대용신탁(유가증권), 010 : 유언대용신탁(부동산))
              //if (tfcori.trstTypC === '007') { // 치매신탁 일 때 (치매신탁에 대한 위임내용 세팅)
              if ((tfcori.trstTypC === '008' && tfcori.trstTypC === '009') || tfcori.trstTypC === '010') {
                //유언대용신탁 위임내용 세팅
                if (!TSCommUtil.gfn_isNull(tfcori.trstOthrEtcAthz) && tfcori.trstOthrEtcAthz !== 'N') {
                  // 기타 내용 설정
                  // 기타내용 설정 없음
                }
              }
            } else {
              // 지급청구대리인일 때
              tmp_agnt_info.agnt_rltn.value = tfcori.prhsRel // 본인과의 관계
              tmp_agnt_info.agnt_rltn.disabled = true

              if (!TSCommUtil.gfn_isNull(tfcori.athzDate)) {
                tmp_agnt_info.cal_athz_dt.value = TSCommUtil.gfn_dateReplace(1, tfcori.athzDate) // 위임기간
              } else {
                tmp_agnt_info.cal_athz_dt.value = TSCommUtil.gfn_dateReplace(1, '99991231')
              }
              tmp_agnt_info.cal_athz_dt.disabled = true
            }

            console.log('fn_AgntSearchResult - tfcori.agntTc > ', tfcori.agntTc)

            if (!TSCommUtil.gfn_isNull(tfcori.agntTc)) {
              tmp_agnt_info.btn_update = false // 수정
              tmp_agnt_info.btn_delete = false // 삭제
              this.lv_btn_search = false // 조회
              this.lv_btn_init = false // 초기화

              console.log('tmp_agnt_info.stateFlag > ', tmp_agnt_info.stateFlag)

              if (tmp_agnt_info.stateFlag === 'update' || tmp_agnt_info.stateFlag === 'D' || tmp_agnt_info.stateFlag === 'insert') {
                let tmpContent = []
                if (tmp_agnt_info.stateFlag === 'update' || tmp_agnt_info.stateFlag === 'D') {
                  tmpContent.push('정상적으로 수정되었습니다.')
                } else if (tmp_agnt_info.stateFlag === 'insert') {
                  tmpContent.push('정상적으로 등록되었습니다.')
                }

                // this.getStore('tsStore').dispatch('IS_PROCUNIT_START') // 단위화면 프로세스 여부 설정
                let t_type = ''
                if (lv_Vm.lv_isProcess) {
                  t_type = 7 // 프로세스 진행 중일 때
                } else {
                  t_type = 9
                }
                lv_Vm.lv_transaction = t_data
                let t_popupRptObj = {
                  confirm: true,
                  confirmFunc: lv_Vm.fn_ReportPrint,
                  confirmData: {
                    tacno: lv_Vm.lv_tac_no.value,
                    bnkbSeq: lv_Vm.lv_bnkb_seq.value,
                    accSeq: lv_Vm.lv_ct_no.value
                  },
                  content: tmpContent,
                  contentTitle: ''
                }
                lv_Vm.fn_AlertPopup(t_type, t_popupRptObj)
              }

              switch (tfcori.agntTc) {
                case 'D':
                case 'D1':
                  this.lv_agnt_info = Object.assign({}, tmp_agnt_info)
                  break
                case 'G':
                case 'G1':
                  this.lv_pay_info_list[0] = Object.assign({}, tmp_agnt_info)
                  // 지급청구2 입력 버튼 비활성화
                  if (t_data.tfccsb.length === 1) {
                    this.lv_pay_info_list[1].btn_insert = false
                    this.lv_pay_info_list[1].agnt_ts_cd = 'G2'
                  }
                  break
                case 'G2':
                  this.lv_pay_info_list[1] = Object.assign({}, tmp_agnt_info)

                  // 지급청구1 입력 버튼 비활성화
                  if (t_data.tfccsb.length === 1) {
                    this.lv_pay_info_list[0].btn_insert = false
                    this.lv_pay_info_list[0].agnt_ts_cd = 'G1'
                  }
                  break
                default:
                  break
              }
            }
          }
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_CustInfoSearch
     * 설명       : 고객정보조회 (selectTFAC27000B)
     ******************************************************************************/
    fn_CustInfoSearch() {
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS27S1'
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600113_S'
      this.eaiCommObj.params = {
        agntCsId: this.lv_cs_id,
        pageRowCnt: '0',
        stndKeyId: '0'
      }
      console.log('fn_CustInfoSearch - eaiCommObj >', this.eaiCommObj)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustInfoSearchResult)
    },
    /******************************************************************************
     * Function명 : fn_CustInfoSearchResult
     * 설명       : 입력 시 고객조회 결과 입력 (selectTFAC27000B)
     ******************************************************************************/
    fn_CustInfoSearchResult(pResultData) {
      console.log('fn_CustInfoSearchResult.....')
      let lv_vm = this
      let t_data = pResultData.data
      if (t_data !== null && t_data !== '') {
        let tfccsb = t_data // ds_detail01 , 대리인 상세조회
        if (!lv_vm.$bizUtil.isEmpty(tfccsb)) {
          let tmp_agnt_info = {}
          if (this.lv_agntType === 'agnt') {
            tmp_agnt_info = lv_vm.fn_SetInitData(this.lv_agntType, this.lv_agnt_info, false, true)
            tmp_agnt_info = Object.assign({}, tmp_agnt_info)
          } else {
            if (lv_vm.agntTc === 'G1' || lv_vm.agntTc === 'G') {
              tmp_agnt_info = lv_vm.fn_SetInitData(this.lv_agntType, this.lv_pay_info_list[0], false, true)
              tmp_agnt_info = Object.assign({}, tmp_agnt_info)
            } else if (lv_vm.agntTc === 'G2') {
              tmp_agnt_info = lv_vm.fn_SetInitData(this.lv_agntType, this.lv_pay_info_list[1], false, true)
              tmp_agnt_info = Object.assign({}, tmp_agnt_info)
            }
          }

          if (TSCommUtil.gfn_isNull(tfccsb.cnm)) {
            this.fn_AlertPopup(0, { content: '대리인 정보가 삭제되었습니다. 수정 또는 삭제하여 재등록해주세요.' })
            return false
          }

          // tmp_agnt_info.agnt_type_cd = lv_vm.trstTypC                                  // 대리인 신탁유형
          tmp_agnt_info.agnt_ts_cd = lv_vm.agntTc // 대리인 신탁코드
          tmp_agnt_info.agnt_cs_id = tfccsb.csId // 대리인 고객 ID

          console.log('fn_CustInfoSearchResult - tmp_agnt_info > ', tmp_agnt_info)

          tmp_agnt_info.agnt_nm.value = ''
          tmp_agnt_info.agnt_nm.value = tfccsb.cnm
          tmp_agnt_info.agnt_nm.disabled = true // 대리인성명

          if (!TSCommUtil.gfn_isNull(tfccsb.rcno)) {
            tmp_agnt_info.ed_rname_no.value = tfccsb.rcno.substr(0, 6) + '-' + tfccsb.rcno.substr(6, 1) + '******' // 실명번호 (마스킹 임시)
          }

          tmp_agnt_info.ed_rname_no.disabled = true

          tmp_agnt_info.phone_no.value1 = ''
          tmp_agnt_info.phone_no.value2 = ''
          tmp_agnt_info.phone_no.value3 = ''
          tmp_agnt_info.phone_no.value1 = tfccsb.mvphBusiMnNo // 이동전화사업자번호
          tmp_agnt_info.phone_no.value2 = tfccsb.mvphPofn // 이동전화국번
          tmp_agnt_info.phone_no.value3 = tfccsb.mvphSeq // 이동전화일련번호
          tmp_agnt_info.phone_no.disabled = true

          tmp_agnt_info.cob_rname_gb.value = ''
          tmp_agnt_info.cob_rname_gb.value = tfccsb.rcnoKindTc // 실명번호 종류
          tmp_agnt_info.cob_rname_gb.disabled = true

          tmp_agnt_info.addr.zipNum = ''
          tmp_agnt_info.addr.value1 = ''
          tmp_agnt_info.addr.value2 = ''
          tmp_agnt_info.addr.value3 = ''
          tmp_agnt_info.mn_cntc_plc_tc = tfccsb.mnCntcPlcTc // 주소 구분
          if (tfccsb.mnCntcPlcTc === '1' || tfccsb.mnCntcPlcTc === '3') {
            // 주연락처구분코드 (1: 자택, 2: 직장, 3: e-mail, etc: 기타)
            tmp_agnt_info.addr.zipNum = tfccsb.hmadPsno1 + tfccsb.hmadPsno2 // 자택주소우편번호1 + 자택주소우편번호2
            tmp_agnt_info.addr.value1 = tfccsb.homePsnoAddr // 자택우편번호주소
            tmp_agnt_info.addr.value2 = tfccsb.homeAddrBldnNo // 자택주소건물번호
            tmp_agnt_info.addr.value3 = tfccsb.homeRmnAddr // 자택잔여주소
            tmp_agnt_info.addr.standard = tfccsb.hmadStdztYn // 자택주소표준화여부

            tmp_agnt_info.tel_no.value1 = tfccsb.homeTelArno // 자택전화지역번호
            tmp_agnt_info.tel_no.value2 = tfccsb.homeTelPofn // 자택전화국번
            tmp_agnt_info.tel_no.value3 = tfccsb.homeTelSeq // 자택전화일련번호
          } else if (tfccsb.mnCntcPlcTc === '2') {
            tmp_agnt_info.addr.zipNum = tfccsb.offcAddrPsno01 + tfccsb.offcAddrPsno02 // 직장주소우편번호01 + 직장주소우편번호02
            tmp_agnt_info.addr.value1 = tfccsb.offcPsnoAddr // 직장우편번호주소
            tmp_agnt_info.addr.value2 = tfccsb.offcAddrBldnNo // 직장주소건물번호
            tmp_agnt_info.addr.value3 = tfccsb.offcRmnAddr // 직장잔여주소
            tmp_agnt_info.addr.standard = tfccsb.offcStdztDtlad // 직장주소표준화여부

            tmp_agnt_info.tel_no.value1 = tfccsb.offcTelArno // 직장전화지역번호
            tmp_agnt_info.tel_no.value2 = tfccsb.offcTelPofn // 직장전화국번
            tmp_agnt_info.tel_no.value3 = tfccsb.offcTelSeq // 직장전화일련번호
          } else {
            tmp_agnt_info.addr.zipNum = tfccsb.etcAddrPsno1 + tfccsb.etcAddrPsno2 // 기타주소우편번호01 + 기타주소우편번호02
            tmp_agnt_info.addr.value1 = tfccsb.etcPsnoAddr // 기타우편번호주소
            tmp_agnt_info.addr.value2 = tfccsb.etcAddrBldnNo // 기타주소건물번호
            tmp_agnt_info.addr.value3 = tfccsb.etcRmnAddr // 기타잔여주소
            tmp_agnt_info.addr.standard = tfccsb.etcStdztDtlad // 기타주소표준화여부
          }

          tmp_agnt_info.addr.disabled = true
          tmp_agnt_info.tel_no.disabled = true

          // 관계
          tmp_agnt_info.agnt_rltn.value = ''

          // 위임기간
          if (lv_vm.lv_agntType === 'agnt') {
            tmp_agnt_info.cal_athz_dt.value = ''
          } else {
            tmp_agnt_info.cal_athz_dt.value = '9999-12-31'
          }

          let tmp_agnt_info_other = {}

          switch (lv_vm.agntTc) {
            case 'D':
            case 'D1':
              lv_vm.lv_agnt_info = Object.assign({}, tmp_agnt_info)
              break
            case 'G':
            case 'G1':
              tmp_agnt_info_other = Object.assign({}, lv_vm.lv_pay_info_list[1])

              lv_vm.lv_pay_info_list = []
              lv_vm.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info))
              lv_vm.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info_other))
              break
            case 'G2':
              tmp_agnt_info_other = Object.assign({}, lv_vm.lv_pay_info_list[0])

              lv_vm.lv_pay_info_list = []
              lv_vm.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info_other))
              lv_vm.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info))
              break
            default:
              // this.lv_agnt_info = Object.assign({}, tmp_agnt_info)
              break
          }
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_SetNoData
     * 설명       : 조회 후 데이터 없을 시 객체 설정
     * 파라미터(type, data) : type - [D1 - 대리인 / G1 - 지급청구1 / G2 - 지급청구2] , data - 객체 정보
     ******************************************************************************/
    fn_SetNoData(type, data) {
      let lv_vm = this
      let tmp_agnt_info_other = {}
      console.log('fn_SetNoData type / data > ', type, data)
      if (!TSCommUtil.gfn_isNull(type)) {
        switch (type) {
          case 'D':
          case 'D1':
            console.log('fn_SetNoData > ', data)
            lv_vm.lv_agnt_info = Object.assign({}, data)
            break
          case 'G':
          case 'G1':
            tmp_agnt_info_other = Object.assign({}, lv_vm.lv_pay_info_list[1])

            lv_vm.lv_pay_info_list = []
            lv_vm.lv_pay_info_list.push(Object.assign({}, data))
            lv_vm.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info_other))
            console.log('fn_SetNoData - lv_vm.lv_pay_info_list > ', lv_vm.lv_pay_info_list)
            break
          case 'G2':
            tmp_agnt_info_other = Object.assign({}, lv_vm.lv_pay_info_list[0])

            lv_vm.lv_pay_info_list = []
            lv_vm.lv_pay_info_list.push(Object.assign({}, tmp_agnt_info_other))
            lv_vm.lv_pay_info_list.push(Object.assign({}, data))
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_SetAthzDt
     * 설명       : 라디오 버튼 클릭 시 위임기간 설정
     * 파라미터  : 0 - 라디오 버튼 클릭 시, 1 - 캘린더 클릭 시
     ******************************************************************************/
    fn_SetAthzDt(type, info = {}) {
      switch (type) {
        case 0:
          this.lv_agnt_info.cal_athz_dt.value = ''
          this.lv_agnt_info.cal_athz_dt_yr.value = ''
          this.lv_agnt_info.cal_athz_dt.disabled = true
          this.lv_agnt_info.cal_athz_dt_yr.disabled = true
          if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
            this.lv_agnt_info.cal_athz_dt.disabled = false
          } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
            this.lv_agnt_info.cal_athz_dt_yr.disabled = false
            this.lv_agnt_info.cal_athz_dt_yr.value = moment(new Date())
              .add(1, 'years')
              .format('YYYY-MM-DD')
          }
          break
        case 1:
          let selDate = ''
          let today = moment(new Date()).format('YYYYMMDD')
          if (this.lv_agntType === 'agnt') {
            if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
              selDate = this.lv_agnt_info.cal_athz_dt.value.replace(/-/g, '')
            } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
              selDate = this.lv_agnt_info.cal_athz_dt_yr.value.replace(/-/g, '')
            }
          } else {
            selDate = info.cal_athz_dt.value.replace(/-/g, '')
          }

          console.log('fn_SetAthzDt > ', today, selDate, today > selDate, today < selDate)

          if (today > selDate) {
            if (this.lv_agntType === 'agnt') {
              this.lv_agnt_info.cal_athz_dt.error = false
              this.lv_agnt_info.cal_athz_dt_yr.error = false
              if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
                this.lv_agnt_info.cal_athz_dt.value = '0000000'
                this.lv_agnt_info.cal_athz_dt.error = true
              } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
                this.lv_agnt_info.cal_athz_dt_yr.value = ''
                this.lv_agnt_info.cal_athz_dt_yr.error = true
              }
            } else {
              info.cal_athz_dt.error = true
              info.cal_athz_dt.value = ''
            }
            this.fn_AlertPopup(0, { content: '현재일보다 이전 날짜는 선택할 수 없습니다.' })
          } else {
            if (this.lv_agntType === 'agnt') {
              if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
                this.lv_agnt_info.cal_athz_dt.error = false
              } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
                this.lv_agnt_info.cal_athz_dt_yr.error = false
              }
            } else {
              info.cal_athz_dt.error = false
            }
          }

          break
      }
    },
    /******************************************************************************
     * Function명 : fn_SetTrstAthz
     * 설명       : 위임내용체크설정
     ******************************************************************************/
    fn_SetTrstAthz(data) {
      let tmpChkArr = []

      // 신탁추가입금위임여부
      if (!TSCommUtil.gfn_isNull(data.trstAddIamtAthzYn) && data.trstAddIamtAthzYn === 'Y') {
        tmpChkArr.push('addIamt')
      }

      // 신탁계약변경위임여부
      if (!TSCommUtil.gfn_isNull(data.trstContChngAthzYn) && data.trstContChngAthzYn === 'Y') {
        tmpChkArr.push('contChng')
      }

      // 신탁계약해지위임여부
      if (!TSCommUtil.gfn_isNull(data.trstContTmtlAthzYn) && data.trstContTmtlAthzYn === 'Y') {
        tmpChkArr.push('contTmtl')
      }

      return tmpChkArr
    },
    /******************************************************************************
     * Function명 : fn_SetParam
     * 설명       : 파라미터 설정
     ******************************************************************************/
    fn_SetParam(type) {
      let param = {}
      let tmp_agnt_info = {}
      if (this.lv_agntType === 'agnt') {
        tmp_agnt_info = Object.assign({}, this.lv_agnt_info)
        param.agntTc = 'D1'
      } else {
        if (this.agntTc === 'G1' || this.agntTc === 'G') {
          tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[0])
          param.agntTc = 'G1'
          // param.agntTc  = 'G '
        } else {
          tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[1])
          param.agntTc = 'G2'
        }
      }

      switch (type) {
        case 'update': // 업데이트 시
          param.tacno = this.lv_tac_no.value
          param.bnkbSeq = this.lv_bnkb_seq.value
          param.ctno = this.lv_ct_no.value
          param.trstTypC = tmp_agnt_info.agnt_type_cd
          param.agntCsId = tmp_agnt_info.agnt_cs_id
          param.stateFlag = this.lv_stateFlag // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응

          // 구분
          if (this.lv_agntType === 'agnt') {
            // 위임기간 설젇
            let tmpAthzDate = ''
            if (tmp_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
              tmpAthzDate = tmp_agnt_info.cal_athz_dt.value.replace(/-/g, '')
            } else if (tmp_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
              tmpAthzDate = tmp_agnt_info.cal_athz_dt_yr.value.replace(/-/g, '')
            } else {
              tmpAthzDate = '00000000'
            }
            param.athzDate = tmpAthzDate
            param.trstAddIamtAthzYn = tmp_agnt_info.chk_cont.value.filter((d) => d === 'addIamt').length !== 0 ? 'Y' : ''
            param.trstContChngAthzYn = tmp_agnt_info.chk_cont.value.filter((d) => d === 'contChng').length !== 0 ? 'Y' : ''
            param.trstContTmtlAthzYn = tmp_agnt_info.chk_cont.value.filter((d) => d === 'contTmtl').length !== 0 ? 'Y' : ''
          } else {
            // 위임기간 설젇
            let tmpAthzDate = ''
            tmpAthzDate = tmp_agnt_info.cal_athz_dt.value.replace(/-/g, '')
            param.athzDate = tmpAthzDate
            param.trstAddIamtAthzYn = ''
            param.trstContChngAthzYn = ''
            param.trstContTmtlAthzYn = ''
          }
          param.prhsRel = TSCommUtil.gfn_trim(tmp_agnt_info.agnt_rltn.value)
          param.pageRowCnt = '0'
          param.stndKeyId = ''

          break
        case 'D':
          param.tacno = this.lv_tac_no.value
          param.bnkbSeq = this.lv_bnkb_seq.value
          param.ctno = this.lv_ct_no.value
          // param.agntTc = tmp_agnt_info.agnt_ts_cd
          param.pageRowCnt = '0'
          param.stndKeyId = ''
          break
      }

      console.log('fn_SetParam - type / param > ', type, param)
      return param
    },
    /******************************************************************************
     * Function명 : fn_SetBtnEnable
     * 설명       : 버튼 설정
     * 파라미터(type) : 버튼 타입 (U: 수정 / I: 입력)
     ******************************************************************************/
    fn_SetBtnEnable(type, info) {
      let tmp_agnt_info = {}
      if (this.lv_agntType === 'agnt') {
        tmp_agnt_info = Object.assign({}, this.lv_agnt_info)
      } else {
        if (this.lv_pay_info_list[0].agnt_ts_cd === 'G1' || this.lv_pay_info_list[0].agnt_ts_cd === 'G') {
          console.log("this.lv_pay_info_list.agnt_ts_cd === 'G1' || this.lv_pay_info_list.agnt_ts_cd === 'G'", this.lv_pay_info_list[0])
          tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[0])
        } else if (this.lv_pay_info_list[1].agnt_ts_cd === 'G2') {
          console.log("this.lv_pay_info_list.agnt_ts_cd === 'G2'", this.lv_pay_info_list[1])
          tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[1])
        }
      }

      this.lv_btn_search = true // 조회 (비활성)
      this.lv_btn_init = true // 초기화 (비활성)

      switch (type) {
        case 'update': // 수정
          tmp_agnt_info.btn_agnt_nm_search = false // 대리인성명 고객조회 버튼 활성화
          tmp_agnt_info.cal_athz_dt.disabled = false // 위임기간
          tmp_agnt_info.agnt_rltn.disabled = false // 본인과의 관계
          if (this.lv_agntType === 'agnt') {
            tmp_agnt_info.rdo_athz_dt.disabled = false // 위임기간
            tmp_agnt_info.chk_cont.disabled = false // 위임내용
          }

          tmp_agnt_info.btn_update = true // 수정 (비활성화)
          tmp_agnt_info.btn_insert = true // 입력 (비활성화)
          tmp_agnt_info.btn_cancle = false // 취소 (활성)
          tmp_agnt_info.btn_confirm = true // 확인 (비활성)
          this.btnAml = false // AML (활성)
          this.lv_stateFlag = type
          break
        case 'insert': // 입력
          tmp_agnt_info.btn_agnt_nm_search = false // 대리인성명 고객조회 버튼 활성화
          tmp_agnt_info.cal_athz_dt.disabled = false // 위임기간
          tmp_agnt_info.agnt_rltn.disabled = false // 본인과의 관계
          if (this.lv_agntType === 'agnt') {
            tmp_agnt_info.rdo_athz_dt.disabled = false // 위임기간
            tmp_agnt_info.chk_cont.disabled = false // 위임내용
          }

          tmp_agnt_info.btn_update = true // 수정 (비활성화)
          tmp_agnt_info.btn_insert = true // 입력 (비활성화)
          tmp_agnt_info.btn_cancle = false // 취소 (활성)
          tmp_agnt_info.btn_confirm = true // 확인 (비활성)
          this.btnAml = false // AML  (활성)
          this.lv_stateFlag = type
          break
        case 'confirm': // 확인  // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응
          tmp_agnt_info.btn_agnt_nm_search = true // 대리인성명 고객조회 버튼 활성화
          tmp_agnt_info.cal_athz_dt.disabled = true // 위임기간
          tmp_agnt_info.agnt_rltn.disabled = true // 본인과의 관계
          if (this.lv_agntType === 'agnt') {
            tmp_agnt_info.rdo_athz_dt.disabled = true // 위임기간
            tmp_agnt_info.chk_cont.disabled = true // 위임내용
          }

          tmp_agnt_info.btn_update = true // 수정 (비활성화)
          tmp_agnt_info.btn_insert = true // 입력 (비활성화)
          tmp_agnt_info.btn_cancle = false // 취소 (활성)
          tmp_agnt_info.btn_confirm = false // 확인 (활성)
          this.btnAml = false // AML (활성)
          this.lv_stateFlag = type
          break
      }

      if (this.lv_agntType === 'agnt') {
        this.lv_agnt_info = Object.assign({}, tmp_agnt_info)
      } else {
        if (tmp_agnt_info.agnt_ts_cd === 'G1' || tmp_agnt_info.agnt_ts_cd === 'G') {
          this.lv_pay_info_list[0] = Object.assign({}, tmp_agnt_info)
        } else if (tmp_agnt_info.agnt_ts_cd === 'G2') {
          this.lv_pay_info_list[1] = Object.assign({}, tmp_agnt_info)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Update
     * 설명       : 수정 이벤트
     ******************************************************************************/
    fn_Update(info = {}) {
      this.stateFlag = 'update'
      this.fn_SetBtnEnable('update', info)
    },

    /******************************************************************************
     * Function명 : fn_Insert
     * 설명       : 입력 이벤트
     ******************************************************************************/
    fn_Insert(info = {}) {
      this.stateFlag = 'insert'
      this.fn_SetBtnEnable('insert', info)
    },

    /******************************************************************************
     * Function명 : fn_Cancle
     * 설명       : 취소 이벤트
     ******************************************************************************/
    fn_Cancle() {
      this.lv_btn_search = false
      this.lv_btn_init = false
      this.fn_AgntContDtlSearch('S') // 대리인 재조회
    },

    /******************************************************************************
     * Function명 : fn_ConfirmAlert
     * 설명       : 확인 알림
     ******************************************************************************/
    fn_ConfirmAlert(type, chk) {
      if (type === 'D') {
        this.fn_CloseModal('Del')
        if (chk === 'Y') {
          this.fn_Delete()
        }
      } else if (type === 'T') {
        this.fn_CloseModal('Tmp')
        if (chk === 'Y') {
          // this.lv_tac_no.value = '1101558'
          this.lv_tac_no.value = '1101587'
          this.fn_AccnoSearch()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Delete
     * 설명       : 삭제
     ******************************************************************************/
    fn_Delete() {
      if (TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
        if (!TSCommUtil.gfn_isNum(this.lv_tac_no.value)) {
          TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
          this.lv_tac_no.error = true
          this.$refs['tac_no'].focus()
          return
        } else {
          this.lv_tac_no.error = false
        }

        this.lv_stateFlag = 'D'

        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS27D1'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600109_S'
        //TO-BE에서는 지급청구대리인(1차)정보만 처리하기로 함
        this.agntTc = 'G1'
        this.eaiCommObj.params = this.fn_SetParam('D')
        console.log('fn_Delete - eaiCommObj >', this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_DeleteResult)
      }
    },

    /******************************************************************************
     * Function명 : fn_DeleteResult
     * 설명       : 입력 수정 삭제 처리시 호출 결과
     ******************************************************************************/
    fn_DeleteResult(pResultData) {
      // 오류 확인
      let t_data = pResultData.data
      if (t_data !== null && t_data !== '') {
        if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
          return false
        }
      }

      this.fn_AgntContDtlSearch('D') // 대리인 재조회
    },

    /******************************************************************************
     * Function명 : fn_Validate
     * 설명       : 등록/수정 시 입력값 검증
     ******************************************************************************/
    fn_Validate() {
      console.log('fn_Validate > ', this.lv_agntType)
      if (this.lv_agntType === 'agnt') {
        // if(TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt.value)) {
        //   TSCommUtil.gfn_validate(this, '필수입력 항목입니다.')
        //   this.lv_agnt_info.cal_athz_dt.error = true
        //   return false
        // }

        if (
          (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1' && TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt.value)) ||
          (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2' && TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt_yr.value))
        ) {
          TSCommUtil.gfn_validate(this, '위임기간을 입력하십시오.')
          if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1' && TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt.value)) {
            this.lv_agnt_info.cal_athz_dt.error = true
          } else if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2' && TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt_yr.value)) {
            this.lv_agnt_info.cal_athz_dt_yr.error = true
          }
          return false
        }

        let tmpAftYrFromNow = moment(new Date())
          .add(12, 'months')
          .format('YYYYMMDD')
        if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_1') {
          let tmpDate = this.lv_agnt_info.cal_athz_dt.value.replace(/-/g, '')

          if (TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt.value) || tmpDate > tmpAftYrFromNow) {
            TSCommUtil.gfn_validate(this, '위임기간을 1년 이내로 입력하십시오.')
            this.lv_agnt_info.cal_athz_dt.error = true
            return false
          }
        }
        if (this.lv_agnt_info.rdo_athz_dt.value === 'dt_type_2') {
          let tmpDate = this.lv_agnt_info.cal_athz_dt_yr.value.replace(/-/g, '')
          // console.log('fn_Validate > 1년뒤 / 찍은 날짜', tmpAftYrFromNow , tmpDate)

          if (TSCommUtil.gfn_isNull(this.lv_agnt_info.cal_athz_dt_yr.value) || tmpDate > tmpAftYrFromNow) {
            TSCommUtil.gfn_validate(this, '위임기간을 1년 이내로 입력하십시오.')
            this.lv_agnt_info.cal_athz_dt_yr.error = true
            return false
          }
        }

        if (TSCommUtil.gfn_isNull(this.lv_agnt_info.agnt_rltn.value)) {
          TSCommUtil.gfn_validate(this, '본인과의 관계를 입력하십시오.')
          // TSCommUtil.gfn_validate(this, '필수입력 항목입니다.')
          this.lv_agnt_info.agnt_rltn.error = true
          this.$refs['agnt_rltn'].focus()
          return false
        }

        if (TSCommUtil.gfn_isNull(this.lv_agnt_info.chk_cont.value) && this.lv_agnt_info.chk_cont.value.length === 0) {
          TSCommUtil.gfn_validate(this, '위임내용을 입력하십시오')
          this.lv_agnt_info.chk_cont.error = true
          return false
        }
      } else {
        let tmp_agnt_info = {}

        // 지급청구 대리인은 1차만 처리 한다.
        tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[0])
        // if(this.agntTc === 'G1' || this.agntTc === 'G') {
        //   tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[0])
        // } else {
        //   tmp_agnt_info = Object.assign({}, this.lv_pay_info_list[1])
        // }

        if (TSCommUtil.gfn_isNull(tmp_agnt_info.cal_athz_dt.value)) {
          TSCommUtil.gfn_validate(this, '필수입력 항목입니다.')
          tmp_agnt_info.cal_athz_dt.error = true
          return false
        }

        if (TSCommUtil.gfn_isNull(tmp_agnt_info.agnt_rltn.value)) {
          TSCommUtil.gfn_validate(this, '본인과의 관계를 입력하십시오.')
          tmp_agnt_info.agnt_rltn.error = true
          this.$refs['agnt_rltn'].focus()
          // TSCommUtil.gfn_validate(this, '필수입력 항목입니다.')
          return false
        }
      }

      return true
    },

    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 입력 시 validate 체크
     ******************************************************************************/
    fn_Confirm(info = {}) {
      this.lv_stateFlag = 'insertcfm' // ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응
      if (!this.fn_Validate()) {
        return false
      }
      this.fn_Transaction()
    },

    /******************************************************************************
     * Function명 : fn_Transaction
     * 설명       : 입력 수정 삭제 처리시 호출
     ******************************************************************************/
    fn_Transaction() {
      console.log('fn_Transaction.....')
      if (TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
        if (!TSCommUtil.gfn_isNum(this.lv_tac_no.value)) {
          TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
          this.lv_tac_no.error = true
          this.$refs['tac_no'].focus()
          return
        } else {
          this.lv_tac_no.error = false
        }

        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS27U1'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600114_S'
        //TO-BE에서는 지급청구대리인(1차)정보만 처리하기로 함
        this.agntTc = 'G1'
        this.eaiCommObj.params = this.fn_SetParam('update')
        console.log('fn_Transaction - eaiCommObj >', this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_TransactionResult)
      }
    },

    /******************************************************************************
     * Function명 : fn_TransactionResult
     * 설명       : 입력 수정 삭제 처리시 호출 결과
     ******************************************************************************/
    fn_TransactionResult(pResultData) {
      // 오류 확인
      let t_data = pResultData.data
      if (t_data !== null && t_data !== '') {
        if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
          return false
        }
      }

      this.fn_AgntContDtlSearch('S') // 대리인 재조회
    },

    /*********************************************************
     * Function명: fn_SetChkAll
     * 설명: 위임내용 모두 체크 이벤트
     *********************************************************/
    fn_SetChkAll() {
      let tmpChkResult = []
      if (this.lv_agntType === 'agnt') {
        tmpChkResult = Object.assign({}, this.lv_agnt_info.chk_cont)
      }

      if (!tmpChkResult.isAll) {
        // 신탁추가입금위임여부
        if (!tmpChkResult.value.includes('addIamt')) {
          tmpChkResult.value.push('addIamt')
        }

        // 신탁계약변경위임여부
        if (!tmpChkResult.value.includes('contChng')) {
          tmpChkResult.value.push('contChng')
        }

        // 신탁계약해지위임여부
        if (!tmpChkResult.value.includes('contTmtl')) {
          tmpChkResult.value.push('contTmtl')
        }
      } else {
        tmpChkResult.value = []
      }

      if (this.lv_agntType === 'agnt') {
        this.lv_agnt_info.chk_cont = tmpChkResult
      }
    },

    /*********************************************************
     * Function명: fn_OpenMSPTS107P
     * 설명: 고객조회 팝업호출
     * 파라미터: type - 00 : 조회조건 팝업 , 01 : 고객정보 입력 , agntTc - 대리인코드
     *********************************************************/
    fn_OpenMSPTS107P(type, agntTc = '') {
      let lv_vm = this
      this.pPopup107Type = type
      this.agntTc = agntTc
      console.log(this.$refs.popup107)
      let properties = {
        pPage: 'MSPTS106M',
        pGdC: lv_vm.gdC, // 신탁상품
        pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: '2000', //
        pProcTc: '2000' //
      }

      this.popup107 = this.$refs.popup107.fn_Open(properties)
    },
    /*********************************************************
     * Function명: fn_Popup107Back
     * 설명: 고객조회 팝업호출 콜백
     *********************************************************/
    fn_Popup107Back(rtnData) {
      //조회조건 고객팝업
      if (this.pPopup107Type === '00') {
        this.lv_tac_no.value = rtnData.tacno
        this.lv_cnm = rtnData.cnm
        this.fn_AccnoSearch()
        //고객정보 입력
      } else if (this.pPopup107Type === '01') {
        this.lv_cs_id = rtnData.csId
        this.fn_CustInfoSearch()
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 고객정보, 1: 우편번호, 2:AML
     * ASR240500691_[사랑on신탁] 자금세탁 위험평가 제도 대응
     ******************************************************************************/
    fn_OpenPopup(type) {
      switch (type) {
        case 0:
          this.$refs.popup107.fn_Open()
          break
        case 1:
          this.$refs.popup100.fn_Open()
          break
        case 2:
          this.$refs.popup303.fn_Open()
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 고객정보, 1: 우편번호
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      console.log(pData)
      let t_popupObj = {}

      switch (type) {
        case 0:
          this.fn_Init()
          this.lv_tac_no.value = pData
          this.fn_AccnoSearch()
          console.log('고객정보 308P callback...', pData)

          // 고객번호 매핑 후 고객정보조회
          // this.lv_ed_cust_no.value = pData
          // this.fn_SearchValid({key: 'v'}, 1)
          break
        case 1:
          console.log('우편번호 100P callback...', pData)
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_SetAgnt
     * 설명       : 대리인으로 설정후 재검색
     ******************************************************************************/
    fn_SetAgnt() {
      this.lv_agntType = 'agnt' //대리인으로 강제 변경
      this.fn_AgntContDtlSearch('S')
    },

    /*********************************************************
     * Function명: fn_OpenModal
     * 설명: 모달팝업 오픈
     *********************************************************/
    fn_OpenModal(type, agntTc = '') {
      this.agntTc = agntTc
      if (type === 'Del') {
        this.modalDel.open()
      } else {
        this.modalTmp.open()
      }
    },

    /*********************************************************
     * Function명: fn_OpenModal
     * 설명: 모달팝업 오픈
     *********************************************************/
    fn_CloseModal(type) {
      this['modal' + type].close()
    },

    // modal
    openModal(type) {
      switch (type) {
        case 1:
          this.modal1.open()
          break

        case 2:
          this.modal2.open()
          break
      }
    },
    closeModal(type) {
      switch (type) {
        case 1:
          this.modal1.close()
          break
        case 2:
          this.modal2.close()
          break
      }
    },
    onClickSearch() {
      this.$addSnackbar('click: searchIcon') // test code
    },

    /**********************************************************************************
     * AML 버튼 클릭시 수행
     **********************************************************************************/
    fn_amlprocess() {
      if (!this.fn_Validate()) {
        return false
      }
      let pParam = {
        tacno: this.lv_tac_no.value, // 종합계좌번호
        bnkbSeq: this.lv_bnkb_seq.value, // 통장일련번호
        ctno: this.lv_ct_no.value, // 계약번호
        agntCsId: this.lv_cs_id,
        stateFlag: this.stateFlag
      }

      let lv_stateFlag = this.lv_stateFlag

      this.sSvcID = 'updateTFAC27000' // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS27U1'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600114_S'
      //TO-BE에서는 지급청구대리인(1차)정보만 처리하기로 함
      this.agntTc = 'G1'
      this.eaiCommObj.params = pParam
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            console.log(res.data)
            this.dsDetail = res.data
            console.log('dsDetail', this.dsDetail)
            this.fn_callBackAml() //호출
          }
        },
        this.fn_error,
        'UF10600114'
      )
    },

    /**********************************************************************************
     * AML 수행 후 콜백
     **********************************************************************************/
    fn_callBackAml() {
      let pParam = {
        tacno: this.lv_tac_no.value, // 종합계좌번호
        bnkbSeq: this.lv_bnkb_seq.value, // 통장일련번호
        ctno: this.lv_ct_no.value, // 계약번호
        agntCsId: this.lv_cs_id,
        stateFlag: 'check'
      }

      if (this.lv_stateFlag == 'update') {
        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS27U1'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600114_S'
        //TO-BE에서는 지급청구대리인(1차)정보만 처리하기로 함
        this.agntTc = 'G1'
        this.eaiCommObj.params = pParam
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              this.dsDetail = res.data
              this.fn_performReAmlYn() //호출
            }
          },
          this.fn_error,
          'UF10600114'
        )
      } else if (this.lv_stateFlag == 'insert') {
        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS27U1'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600114_S'
        //TO-BE에서는 지급청구대리인(1차)정보만 처리하기로 함
        this.agntTc = 'G1'
        this.eaiCommObj.params = this.fn_SetParam('update')
        console.log('fn_Transaction - eaiCommObj >', this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_btnAmlPopup)
      } else if (this.lv_stateFlag == 'repro') {
        this.fn_AlertPopup(0, { content: ' repro : fn_btnAmlPopup 바로전' })
        sSvcID = 'updateTFAC27000' //AML후 확인버튼 수행하면 콜되는서비스 setting

        this.fn_btnAmlPopup() //AML 화면호출
      }
    },

    /**********************************************************************************
     * 위험평가수행이력 조회. 2024.06.18 add
     **********************************************************************************/
    fn_performReAmlYn() {
      this.sSvcID = 'selectTPUP95020CList' // 위험평가수행이력 조회
      /*서비스 호출시 정보 세팅*/
      let pParam = {
        tacno: this.lv_tac_no.value, // 종합계좌번호
        bnkbSeq: this.lv_bnkb_seq.value, // 통장일련번호
        ctno: this.lv_ct_no.value, // 계약번호
        agntCsId: this.lv_cs_id,
        procSeq: this.dsDetail.amlProcSeq
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600194_S'
      this.eaiCommObj.params = pParam
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            console.log(res.data)
            this.tftarlList = res.data.tftarl
            console.log('dsList20', this.tftarlList)
            this.fn_btnAmlPopup() //호출
          }
        },
        function(error) {
          return
        },
        'UF10600194'
      )
    },

    /**********************************************************************************
     * AML 등록 팝업
     **********************************************************************************/
    fn_btnAmlPopup() {
      let t_data = this.dsList20
      let vTacno = ''
      let vBnkbSeq = ''
      let vCtno = ''
      let vProcSeq = ''

      vTacno = this.lv_tac_no.value
      vBnkbSeq = this.lv_bnkb_seq.value
      vCtno = this.lv_ct_no.value
      vProcSeq = this.dsDetail.amlProcSeq

      this.sPopupId = 'TPUP95020'
      let properties = {
        tacno: vTacno,
        bnkbSeq: vBnkbSeq,
        ctno: vCtno,
        procSeq: vProcSeq,
        vcnm: this.lv_cnm // ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
      }

      this.popup303 = this.$refs.popup303.fn_Open(properties)
    },

    /*********************************************************
     * 설명: get 계좌 조회조건 정보
     *********************************************************/
    fn_getAcnoCondInfo() {
      console.log('fn_getAcnoCondInfo타는가?')
      let edtAcno1 = thisWin.edtAcno1
      let cboAcno = thisWin.cboAcno.replaceAll('-', '')
      let cboCtno = thisWin.cboCtno

      let edtAcno2 = thisWin.edtAcno2
      let cboAccSeq = thisWin.cboAccSeq
      let cboChngSeq = thisWin.cboChngSeq

      let acnoInfo = {}
      if (cboAccSeq == null || cboAccSeq == '') {
        acnoInfo['tacno'] = edtAcno1
        acnoInfo['bnkbSeq'] = cboAcno.substr(7, 3)
        acnoInfo['accSeq'] = ''
        acnoInfo['ctno'] = cboCtno
        acnoInfo['chngSeq'] = ''
      } else {
        acnoInfo['tacno'] = edtAcno2.substr(0, 7)
        acnoInfo['bnkbSeq'] = edtAcno2.substr(7, 3)
        acnoInfo['accSeq'] = cboAccSeq
        acnoInfo['ctno'] = ''
        acnoInfo['chngSeq'] = cboChngSeq
      }
      return acnoInfo
    },

    /*********************************************************
     * 팝업화면에서 보낸 파라미터 받는곳(id, param)
     * param은 문자열만 가능(object으로 받을 수 없음. 즉 Dataset은 안됨)
     *********************************************************/
    async fn_popupAfter() {
      this.sSvcID = 'updateTFAC27000'
      console.log('confirm 들어온건지?', this.stateFlag)
      this.fn_SetBtnEnable('confirm', {})
    }
  },

  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    'lv_agnt_info.chk_cont.value': {
      handler(after, before) {
        if (!TSCommUtil.gfn_isNull(after)) {
          console.log('lv_agnt_info.chk_cont.value - after', after)
          let agntChkArrAll = ['addIamt', 'contChng', 'contTmtl']
          console.log(
            'lv_agnt_info.chk_cont.value - filter',
            this.lv_agnt_info.chk_cont.value.filter((d) => agntChkArrAll.includes(d))
          )
          if (this.lv_agnt_info.chk_cont.value.filter((d) => agntChkArrAll.includes(d)).length === 3) {
            this.lv_agnt_info.chk_cont.isAll = true
          } else {
            // 하나라도 포함하지 않음
            this.lv_agnt_info.chk_cont.isAll = false
          }
        } else {
          // 모두 포함하지 않음
          this.lv_agnt_info.chk_cont.isAll = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss"></style>

<style scoped></style>
