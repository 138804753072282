/*
  DataSet 
 ***********************************************************************************
 *       수정일         이름         사유
 ***********************************************************************************
 *    2023.03.15.       지재호      MSPTS100M 에서 사용할 dataSet 외부파일로 정리
 
 */
 const TSDataSet = {}
  
  TSDataSet.ds_detail = {
    csId : '',
    lastChngDt : '',
    lastChngMnTmno : '',
    lastChngMnUsid : '',
    rcnoKindTc : '01',
    rcno : '',
    cono : '',
    persnBusiMnBsno : '',
    cnm : '',
    admkNm : '',
    csStatC : '',
    csEnnm : '',
    ennmUseYn : '',
    repMnCnoId : '',
    repMnNm : '',
    repMnRcno : '',
    corpYn : '',
    natnNatC : '',
    rsdnNatC : '',
    rsdnYn : '',
    cdinProfrCnsntYn : 'Y', // 신용동의 여부 계약체결
    mktnCnsntYn : '',  // 
    mktnCnsntTrm : '', // 마케팅동의기간
    mktnCnsntDate : '', // 마케팅 동의일자(=sysdate)
    valdEndDate : '', // 유효종료일자
    mktnInvtTelYn : '', // 마케팅권유전화여부
    mktnInvtSsYn : '',  // 마케팅권유SMS여부
    mktnInvtEmailYn : '', // 마케팅권유이메일여부
    mktnInvtMailYn : '',  // 마케팅권유우편여부
    trCsYn : '',  // 거래고객여부
    incMnTc : '111', // 소득자구분
    genTaxtTc : '',
    lvlhTc: '',
    btdt: '',
    dateTc: '',
    psprt: '',
    mnCntcPlcTc: '', // 주연락처
    psmtRcvTc: '',
    hmadPsno: '', // 전체 우편번호 
    hmadPsno1: '', // HOME 우편번호1 
    hmadPsno2: '', // HOME 우편번호2 
    homeAddrBldnNo: '', // 건물번호
    hmadPsnoSeq: '', // 
    homeAddrPsnoSeq: '',
    homePsnoAddr: '', // 우편주소 all
    homeRmnAddr: '', // 상세주소(표준주소) // 상세주소 + 부가주소 
    homeStdztDtlad : '',
    homeAdtnAddr : '',
    homeTelArno: '',
    homeTelPofn: '',
    homeTelSeq: '',
    homeTno: '',
    mvphBusiMnNo: '',
    mvphPofn: '',
    mvphSeq: '',
    moveTno: '',
    emailAddr: '',
    domnNm: '',
    offcNm: '',
    dpnm: '',
    jobps: '',
    incoDate: '',
    offcAddrPsno: '', // 전체 우편번호
    offcAddrPsno01: '', // 회사 우편번호1
    offcAddrPsno02: '', // 회사 우편번호2
    offcAddrBldnNo: '',
    offcAddrPsnoSeq: '',
    offcPsnoAddr: '',
    offcRmnAddr: '',
    offcTelArno: '',
    offcTelPofn: '',
    offcTelSeq: '',
    offcTno: '',
    offcTelEtsNo: '',
    offcFaxArno: '',
    offcFaxPofn: '',
    offcFaxSeq: '',
    offcFaxNo: '',
    etcAddrPsno1: '',
    etcAddrPsno2: '',
    etcAddrBldnNo: '',
    etcAddrPsnoSeq: '',
    etcPsnoAddr: '',
    etcRmnAddr: '',
    etcTelArno: '',
    etcTelPofn: '',
    etcTelSeq: '',
    etcTelNo : '',
    hmadStdztYn : '',
    offcAddrStdztYn : '',
    etcAddrStdztYn : '',
    etcAddrUseYn : '',
    stactMm : '',
    emplFgr : '',
    srcLvesAthzYn : '',
    dvtxType : '',
    taxtYn : '',
    bnkbLastSeq : '',
    matrnBnkAcno : '',
    rmtBkcd01 : '',
    rebkAcno01 : '',
    rebkAcnoDepoNm01 : '',
    rmtBkcd02 : '',
    rebkAcno02 : '',
    rebkAcnoDepoNm02 : '',
    chmnCsId1 : '',
    chmnRcno1 : '',
    chmnName1 : '',
    chmnDpnm1 : '',
    chmnRenm1 : '',
    chmnTelArno1 : '',
    chmnTelPofn1 : '',
    chmnTelSeq1 : '',
    chmnTelNo1 : '',
    chmnEmailAddr1 : '',
    chmnDomnNm1 : '',
    chmnCsId2 : '',
    chmnRcno2 : '',
    chmnName2 : '',
    chmnDpnm2 : '',
    chmnRenm2 : '',
    chmnTelArno2 : '',
    chmnTelPofn2 : '',
    chmnTelSeq2 : '',
    chmnTelNo2 : '',
    chmnEmailAddr2 : '',
    chmnDomnNm2 : '',
    gTc : '',
    insrJobTc : '',
    insrStndIndsTypeC : '',
    insrJobC : '',
    estbDate : '',
    mbrNat : '',
    busiPlcNat : '',
    listStxcTc : '',
    listStxcEtc : '',
    listYn : '',
    estbPpsTc : '',
    estbPpsEtc : '',
    trPpsTc : '',
    trPpsEtc : '',
    fdscType : '',
    fdSrcOrgplEtcCntn : '',
    totAstsPnstaTc : '',
    hamtWlprsYn : '',
    ioAmtTrPpsC : '',
    accOpenPpsC : '',
    cno : '',
    csInfoCrctTc : '',
    newMemberYn : '',
    noTacct : '',
    tacPswd1 : '',
    tacPswd2 : '',
    doNotCallTelYn : '',
    doNotCallSmsYn : '',
    tacno : '',
    trstVaBkcd : '020', // 우리은행 하나만 있음. 
    trstVtAcno : '',
    bncrVaBkcd : '',
    bncrVtAcno : '',
    invtMnEmno : '',      // 권유자 사번
    invtMnEmnoNm : '',    // 권유자 이름
    mngrEmno : '',        // 관리자 사번
    mngrEmnoNm : '',      // 관리자 이름
    fnlDsgnEmno : '',     // 권유FC 사번
    fnlDsgnEmnoNm : '',   // 권유FC 이름
    aprvNo : '',
    agntDelYn : '',
    offcStdztDtlad : '',
    offcAdtnAddr : '',
    etcStdztDtlad : '',
    etcAdtnAddr : '',
    spltDstyStatC : '',
    cal_btdt : '',  // 생일
    sexTc : '',  // 성별
    hid_edt_csIdByRcno : '',
    hid_chk_AgntDelYn: '',
  }

  TSDataSet.ds_cdiId = {
    sliPCustId: '',
    tcrmPCustId: '',
    custCustId: '',
    sliPTrtDtm: '',
    tcrmPBobjNmVOpCustId: '',
    tcrmPBobjNmVOcustTypCd: '',
    tcrmPBobjNmVOcustStatCd: '',
    tcrmPBobjNmVOcustTrtDtm: '',
    tcrmPBobjNmVOpTrtDtm: '',
    tcrmPGvnmBobjNmVOpNamePkId: '',
    tcrmPGvnmBobjNmVOpCustId: '',
    tcrmPGvnmBobjNmVOpNameTrtDtm: '',
    custAddrNmVOcustAddrPkId1: '',
    custAddrNmVOaddrId1: '',
    custAddrNmVOcustId1: '',
    custAddrNmVOcustCtadrRltnTrtDtm1: '',
    custAddrNmVOcustAddrRltnTrtDtm1: '',
    addrNmVOaddrPkId1: '',
    addrNmVOpstcdId1: '',
    addrNmVOpstcdSeqNo1: '',
    addrNmVOdtlad1: '',
    addrNmVOaddrStdztYn1: '',
    addrNmVOaddrTrtDtm1: '',
    addrGrpExpnBobjNmVOcustCtadrRltnId1: '',
    addrGrpExpnBobjNmVOaddrGrpExpnTrtDtm1: '',
    custAddrNmVOcustAddrPkId2: '',
    custAddrNmVOaddrId2: '',
    custAddrNmVOcustId2: '',
    custAddrNmVOcustCtadrRltnTrtDtm2: '',
    custAddrNmVOcustAddrRltnTrtDtm2: '',
    addrNmVOaddrPkId2: '',
    addrNmVOpstcdId2: '',
    addrNmVOdtlad2: '',
    addrNmVOpstcdSeqNo2: '',
    addrNmVOaddrStdztYn2: '',
    addrNmVOaddrTrtDtm2: '',
    addrGrpExpnBobjNmVOcustCtadrRltnId2: '',
    addrGrpExpnBobjNmVOaddrGrpExpnTrtDtm2: '',
    custCtadrNmVOcustCmmuPkId1: '',
    custCtadrNmVOcmmuId1: '',
    custCtadrNmVOcustId1: '',
    custCtadrNmVOcustCtadrRltnTrtDtm1: '',
    custCtadrNmVOcustCmmuRltnTrtDtm1: '',
    ctadrBobjNmVOcmmuPkId1: '',
    ctadrBobjNmVOknb1: '',
    ctadrBobjNmVOcmmuTrtDtm1: '',
    custCtadrNmVOcustCmmuPkId2: '',
    custCtadrNmVOcmmuId2: '',
    custCtadrNmVOcustId2: '',
    custCtadrNmVOcustCtadrRltnTrtDtm2: '',
    custCtadrNmVOcustCmmuRltnTrtDtm2: '',
    ctadrBobjNmVOcmmuPkId2: '',
    ctadrBobjNmVOknb2: '',
    ctadrBobjNmVOcmmuTrtDtm2: '',
    custCtadrNmVOcustCmmuPkId3: '',
    custCtadrNmVOcmmuId3: '',
    custCtadrNmVOcustId3: '',
    custCtadrNmVOcustCtadrRltnTrtDtm3: '',
    custCtadrNmVOcustCmmuRltnTrtDtm3: '',
    ctadrBobjNmVOcmmuPkId3: '',
    ctadrBobjNmVOknb3: '',
    ctadrBobjNmVOcmmuTrtDtm3: '',
    custCtadrNmVOcustCmmuPkId4: '',
    custCtadrNmVOcmmuId4: '',
    custCtadrNmVOcustId4: '',
    custCtadrNmVOcustCtadrRltnTrtDtm4: '',
    custCtadrNmVOcustCmmuRltnTrtDtm4: '',
    ctadrBobjNmVOcmmuPkId4: '',
    ctadrBobjNmVOknb4: '',
    ctadrBobjNmVOcmmuTrtDtm4: '',
    custCtadrNmVOcustCmmuPkId5: '',
    custCtadrNmVOcmmuId5: '',
    custCtadrNmVOcustId5: '',
    custCtadrNmVOcustCtadrRltnTrtDtm5: '',
    custCtadrNmVOcustCmmuRltnTrtDtm5: '',
    ctadrBobjNmVOcmmuPkId5: '',
    ctadrBobjNmVOknb5: '',
    ctadrBobjNmVOcmmuTrtDtm5: '',
    pExpnBobjNmVOexpnCustId: '',
    pExpnBobjNmVOpExpnTrtDtm: '',
    jobpBobjNmVOcustId: '',
    jobpBobjNmVOjobpTrtDtm: '',
    custPprefNmVOcustPprefPkId1: '',
    custPprefNmVOcustId1: '',
    custPprefNmVOpprefTypCd1: '',
    custPprefNmVOpprefActnOptId1: '',
    custPprefNmVOpprefRsnTypCd1: '',
    custPprefNmVOpSrcDsmtTypCd1: '',
    custPprefNmVOstrYmd1: '',
    custPprefNmVOendYmd1: '',
    custPprefNmVOentyPprefTrtDtm1: '',
    custPprefNmVOpprefTrtDtm1: '',
    custPprefNmVOcustPprefPkId2: '',
    custPprefNmVOcustId2: '',
    custPprefNmVOpprefTypCd2: '',
    custPprefNmVOpprefActnOptId2: '',
    custPprefNmVOpprefRsnTypCd2: '',
    custPprefNmVOpSrcDsmtTypCd2: '',
    custPprefNmVOstrYmd2: '',
    custPprefNmVOendYmd2: '',
    custPprefNmVOentyPprefTrtDtm2: '',
    custPprefNmVOpprefTrtDtm2: '',
  }

  TSDataSet.ds_cdiIdct = {
    idctTcrmPBobjNmVOsxdsCd: '',
    idctTcrmPBobjNmVOralDob: '',
    idctTcrmPBobjNmVOnatyCd: '',
    idctTcrmPGvnmBobjNmVOcustNm: '',
    idctAddrNmVOpstcdId1: '',
    idctAddrNmVOpstcdSeqNo1: '',
    idctAddrNmVOdtlad1: '',
    idctAddrNmVOnwOldAddrScCd1: '',
    idctAddrNmVOaddrStdztYn1: '',
    idctAddrNmVOendYmd1: '',
    idctAddrNmVOpstcdId2: '',
    idctAddrNmVOpstcdSeqNo2: '',
    idctAddrNmVOdtlad2: '',
    idctAddrNmVOnwOldAddrScCd2: '',
    idctAddrNmVOaddrStdztYn2: '',
    idctAddrNmVOendYmd2: '',
    idctCtadrBobjNmVOknb1: '',
    idctCtadrBobjNmVOendYmd1: '',
    idctCtadrBobjNmVOknb2: '',
    idctCtadrBobjNmVOendYmd2: '',
    idctCtadrBobjNmVOknb3: '',
    idctCtadrBobjNmVOendYmd3: '',
    idctCtadrBobjNmVOknb4: '',
    idctCtadrBobjNmVOendYmd4: '',
    idctCtadrBobjNmVOknb5: '',
    idctCtadrBobjNmVOendYmd5: '',
    idctpExpnBobjNmVOdmclScCd: '',
    idctpExpnBobjNmVOpsprtNo: '',      
  }

  TSDataSet.ds_detailBfc = {
    csId: '',
    lastChngDt: '',
    lastChngMnTmno: '',
    lastChngMnUsid: '',
    rcnoKindTc: '01',
    rcno: '',
    cono: '',
    persnBusiMnBsno: '',
    cnm: '',
    admkNm: '',
    csStatC: '',
    csEnnm: '',
    ennmUseYn: '',
    repMnCnoId: '',
    repMnNm: '',
    repMnRcno: '',
    corpYn: '',
    natnNatC: '',
    rsdnNatC: '',
    rsdnYn: 'Y',
    cdinProfrCnsntYn: 'Y',
    mktnCnsntYn: 'N',
    mktnCnsntTrm: '',
    mktnCnsntDate: '',
    valdEndDate: '',
    mktnInvtTelYn: '',
    mktnInvtSsYn: '',
    mktnInvtEmailYn: '',
    mktnInvtMailYn: '',
    incMnTc: '',
    genTaxtTc: '',
    lvlhTc: '',
    btdt: '',
    dateTc: '',
    sexTc: '',
    psprt: '',
    mnCntcPlcTc: '',
    psmtRcvTc: '',
    hmadPsno: '', // 전체 우편번호 
    hmadPsno1: '',
    hmadPsno2: '',
    homeAddrBldnNo: '',
    hmadPsnoSeq: '',
    homeAddrPsnoSeq:'',
    homePsnoAddr: '',
    homeRmnAddr: '',
    homeTelArno: '',
    homeTelPofn: '',
    homeTelSeq: '',
    homeTno: '',
    mvphBusiMnNo: '',
    mvphPofn: '',
    mvphSeq: '',
    moveTno: '',
    emailAddr: '',
    domnNm: '',
    offcNm: '',
    dpnm: '',
    jobps: '',
    incoDate: '',
    offcAddrPsno: '', // 전체 우편번호
    offcAddrPsno01: '',
    offcAddrPsno02: '',
    offcAddrBldnNo: '',
    offcAddrPsnoSeq: '',
    offcPsnoAddr: '',
    offcRmnAddr: '',
    offcTelArno: '',
    offcTelPofn: '',
    offcTelSeq: '',
    offcTno: '',
    offcTelEtsNo: '',
    offcFaxArno: '',
    offcFaxPofn: '',
    offcFaxSeq: '',
    offcFaxNo: '',
    etcAddrPsno1: '',
    etcAddrPsno2: '',
    etcAddrBldnNo: '',
    etcAddrPsnoSeq: '',
    etcPsnoAddr: '',
    etcRmnAddr: '',
    etcTelArno: '',
    etcTelPofn: '',
    etcTelSeq: '',
    etcTelNo: '',
    hmadStdztYn: '',
    offcAddrStdztYn: '',
    etcAddrStdztYn: '',
    etcAddrUseYn: '',
    stactMm: '',
    emplFgr: '',
    srcLvesAthzYn: '',
    dvtxType: '',
    taxtYn: '',
    bnkbLastSeq: '',
    regDate: '',
    matrnBnkAcno: '',
    rmtBkcd01: '',
    rebkAcno01: '',
    rebkAcnoDepoNm01: '',
    rmtBkcd02: '',
    rebkAcno02: '',
    rebkAcnoDepoNm02: '',
    chmnCsId1: '',
    chmnRcno1: '',
    chmnName1: '',
    chmnDpnm1: '',
    chmnRenm1: '',
    chmnTelArno1: '',
    chmnTelPofn1: '',
    chmnTelSeq1: '',
    chmnTelNo1: '',
    chmnEmailAddr1: '',
    chmnDomnNm1: '',
    chmnCsId2: '',
    chmnRcno2: '',
    chmnName2: '',
    chmnDpnm2: '',
    chmnRenm2: '',
    chmnTelArno2: '',
    chmnTelPofn2: '',
    chmnTelSeq2: '',
    chmnTelNo2: '',
    chmnEmailAddr2: '',
    chmnDomnNm2: '',
    gTc: '',
    insrJobTc: '1',
    insrStndIndsTypeC: '',
    insrJobC: '',
    estbDate: '',
    mbrNat: '',
    busiPlcNat: '',
    listStxcTc: '',
    listStxcEtc: '',
    listYn: '',
    estbPpsTc: '',
    estbPpsEtc: '',
    trPpsTc: '',
    trPpsEtc: '',
    fdscType: '',
    fdSrcOrgplEtcCntn: '',
    totAstsPnstaTc: '',
    hamtWlprsYn: '',
    trCsYn: '',
    ioAmtTrPpsC: '',
    accOpenPpsC: '',
    cno: '',
    csInfoCrctTc: '',
    newMemberYn: '',
    noTacct: '',
    tacPswd1: '',
    tacPswd2: '',
    doNotCallTelYn: '',
    doNotCallSmsYn: '',
    tacno: '',
    trstVaBkcd: '',
    trstVtAcno: '',
    bncrVaBkcd: '',
    homeStdztDtlad : '',
    homeAdtnAddr : '',    
    offcStdztDtlad : '',
    offcAdtnAddr : '',
  }

  TSDataSet.ds_detail01 = {
    csId: '',
    lastChngDt: '',
    lastChngMnTmno: '',
    lastChngMnUsid: '',
    prhsRel: '',
    accOpngAthzYn: '',
    appnIndcAthzYn: '',
    bktrStipAthzYn: '',
    accFtrnStipAthzYn: '',
    trstContAthzYn: '',
    trstContChngAthzYn: '',
    trstContTmtlAthzYn: '',
    etcYn: '',
    etcExpl: '',
    agntCsId: '',
    agntCnm: '',
  }

  TSDataSet.ds_search = {
    rcnoKindTc: '01',
    rcno: '', 
  }
  
  // TSDataSet.ds_natnCd = {
  //   cNm : '',
  //   c : '',
  // }

  TSDataSet.ds_search01 = {
    rcnoKindTc : '01',
    rcno : '',
    cnm : '',
    cdinProfrCnsntYn : '',
    mktnCnsntYn : 'N',
  }

  TSDataSet.ds_detailFatca = {
    csId: '',
    clctnDate: '',
    clctnSeq: '',
    lastChngDt: '',
    lastChngMnTmno: '',
    lastChngMnUsid: '',
    clctnMedTyp: '',
    corpYn: '',
    trgpTc: '',
    sbmtYn: '',
    cnt: '',
  }

  TSDataSet.ds_searchFatca = {
    csId: '',
    corpYn: '',    
  }

  TSDataSet.ds_cdiList = {
    custNm: '',
    custId: '',
    custDtlTypCd: '',
    custStatCd: '',
    pprefRsnTypCd: '',
  }
  
  TSDataSet.ds_admission = {
    mngAprvSeq: '',
    aprvNo: '',
    tacno: '',
  }

  TSDataSet.ds_cuCust = {
    csId: '',
    cnm: '',
  }

  TSDataSet.ds_cdiChgNd = {
    chgNdVOtcrmPBobjNm: '',
    chgNdVOtcrmPGvnmBobjNm: '',
    chgNdVOcustAddrNm1: '',
    chgNdVOcustAddrNm2: '',
    chgNdVOcustCtadrNm1: '',
    chgNdVOcustCtadrNm2: '',
  }

  TSDataSet.ds_linkAcctDetail = {
    msUseYn: '',
    vtAcnoYn: '',
    tacno: '',
    bnkbSeq: '',
    refNo: '',
  }

  TSDataSet.ds_rdSearch = {
    csId: '',
    tacno: '',
    bnkbSeq: '',
    corpYn: '',
  }

  TSDataSet.ds_listFc = {
    fcEmno: '',
    fcNm: '',
    rcno: '',
    etpsDpcd: '',
    plzaNm: '',
  }

  TSDataSet.ds_searchFc = {
    fcEmno: '',
  }

  TSDataSet.ds_9998 = {
    eaiSystemName: '',
  }
  
  TSDataSet.enabledList = {
    edt_cnm: false, // 고객명
    cmb_natnNatC: false,  // 국적
    cmb_rsdnNatC: false,  // 거주국가
    edt_csEnnm: false,  // 고객영문명
    cmb_incMnTc: false,  // 소득자구분
    cmb_lvlhTc: false, // 생계형구분
    rdo_rsdnYn: false, // 거주자여부 
    rdo_sexTc: false,  // 성별
    rdo_mnCntcPlcTc: false, // 주연락처
    cal_btdt: false, // 생일
    rdo_dateTc: false, // 양력음력구분
    rdo_cdinProfrCnsntYn: false, // 신용정보동의여부
    rdo_mktnCnsntYn: false, // 마케팅동의여부
    edt_agntNm: false, // ???????
    cmb_modifyGb: '', // 정정구분 > 수정체크
  

    btn_popupTpup95100_1: false, // 우편번호
    edt_homeTelArno: false, // 자택지역번호
    edt_homeTelPofn: false, // 자택국번호
    edt_homeTelSeq: false,  // 자택전화일련번호
    

    edt_emailAddr: false, // 이메일
    edt_domnNm: false, // 이메일도메인
    edt_faxArno: false, 
    edt_faxPofn: false, 
    edt_faxSeq: false, 
    cmb_mvphBusiMnNo: false, // 이동전화사업자번호 (연락처벙보)
    edt_mvphPofn: false, // 이동전화국번  (연락처벙보)
    edt_mvphSeq: false, // 이동전화일련번호 (연락처벙보)
  
    edt_offcNm: false, // 직장명
    edt_dpnm: false, // ?????
    cmb_insrJobTc: false, // 보험직업구분
    btn_popupTpup95100_2: false, // 우편번호
    edt_offcTelArno: false, // edt_offcTelArno
    edt_offcTelPofn: false, // 직장국번호
    edt_offcTelSeq: false, // 직장전화일련번호
    btn_popupTpup95230: false, // 보험코드
    cal_incoDate: false, 
    edt_jobps: false, 
    cmb_ioAmtTrPpsC: false, // 입출금 거래목적
    cmb_accOpenPpsC: false, // 계좌신규목적
    edt_tacPswd1: false, 
    edt_tacPswd2: false, 
    edt_bncrVtAcno: false, 
    msk_trstVtAcno: false, 
    edt_invtMnEmno: false, // 권유자
    btn_popupTPUP95110_1: false, // 사원찾기
    edt_mngrEmno: false, // 관리자
    btn_popupTPUP95110_2: false, // 사원찾기
    edt_fnlDsgnEmno: false, 
    btn_popupTPUP95290: false, // 사원찾기
    btn_popupTpup95050: false, // FATCA 대상자 확인
    edt_insrJobC: false, // 보험직업구분

    cmb_rcnoKindTc: true, // 실명번호 종류
    edt_rcno: true, // 실명번호

    cmb_bankCode: false,
    btn_popupTfcp10001: false, // 신탁별도주소 입력버튼
    edt_offcFax: false,


    // 버튼
    btn_searchList: true, // 조회
    btn_update: false, // 수정
    btn_confirm: false, // 확인
    btn_cancel: false, // 취소
    btn_init: true, // 초기화

    /* vue 컨버팅시 필요한 값 추가 */
    sta_modifyGb: '', // 정정구분
    btn_popupTpup96000: '', // 대리인버튼 비활성화
    rdo_representative: '', // 대리인여부
  }

  /**
   * 화면 visible 여부
   * 기본값 ''
   * true : 화면에서 보임 , false: 보이지 않음
   */
  TSDataSet.visibleList = {
    sta_modifyGb: '',         // 정정구분 제목
    cmb_modifyGb: '',         // 정정구분 콤보
    btn_TFBM32000: '',        // 신규종합계좌개설연결버튼 초기화
    sta_sub5: '',             // 신규종합계좌개설
    chk_NoTacct: '',          // 신규 종합계좌 미개설
    sta_notice00: '',         // 연락방식 : ※ 종합계좌 미개설 대상(대리인 / 사무처리담당자에 한함
    Static00: '',             // 의미 없는듯...
    Static59: '',             // 관리자 가림
    Static60: '',             // 비밀번호, 비밀번호확인, 권유자 3개 항목 
    Static66: '',             // 비밀번호 텍스트 가림...
    Static67: '',             // 가상계좌번호 텍스트 가림
    Static69: '',             // 권유자 텍스트
    Static70: '',             // 비밀번호 텍스트
    Static71: '',             // 권유FC 텍스트, input 영역
    Static72: '',             // 관리자 텍스트
    Static73: '',             // 의미없음
    Static74: '',             // 권유FC 텍스트
    Static76: '',             // 가상계좌번호 input 입력 영역
    Static75: '',             // 의미없음
    noTacct: '',              // 신규종합계좌개설 영영 전체
    edt_tacPswd1: '',
    edt_tacPswd2: '',
    edt_tacno: '',
    edt_bncrVtAcno: '',
    cmb_bankCode: '',
    msk_trstVtAcno: '',
    edt_invtMnEmno: '',
    btn_popupTPUP95110_1: '',
    edt_invtMnNm: '',
    edt_mngrEmno: '',
    btn_popupTPUP95110_2: '',
    edt_mngrNm: '',
    edt_fnlDsgnEmno: '',
    btn_popupTPUP95110_3: '',
    btn_popupTPUP95290: '',
    edt_fnlDsgnNm: '',
    Static00: '',
    sta_notice1: '',                  // 자택필수입력메세지
    sta_notice2: '',                  // 직장필수입력메세지 (직장 필수 입력해야 합니다.)
    sta_notice11: '',                 // 자택 또는 직장주소 필수 입력 해야 합니다. 
    chk_homeAddr: '',     
    chk_homeTel: '',
    chk_homeFax: '',
    chk_homeCel: '',
    chk_offcAddr: '',
    chk_offcTel: '',
    chk_homeEmail: '',
    chk_etcAddr: '',
    chk_sbmtYn: '', // Div01

    hid_edt_homeAddrBldnNo: '',
    hid_edt_offcAddrBldnNo: '',

    homeAddrBldnNo: '',
    offcAddrBldnNo: '',
    mktnCnsntYn:'', // 마케팅 연락방식
    
  }

  // 국적. 기존 넥사크로 소스에 하드 코딩 되어 있었음
  TSDataSet.cmb_natnNatC = [
    {value: "GH", text: "가나"},
    {value: "GA", text: "가봉"},
    {value: "GY", text: "가이아나"},
    {value: "GM", text: "감비아"},
    {value: "GT", text: "과테말라"},
    {value: "GD", text: "그레나다"},
    {value: "GR", text: "그리스"},
    {value: "GL", text: "그린란드"},
    {value: "GN", text: "기니"},
    {value: "GW", text: "기니비사우"},
    {value: "NA", text: "나미비아"},
    {value: "NR", text: "나우르"},
    {value: "NG", text: "나이지리아"},
    {value: "ZA", text: "남아프리카"},
    {value: "NL", text: "네덜란드"},
    {value: "NP", text: "네팔"},
    {value: "NO", text: "노르웨이"},
    {value: "NZ", text: "뉴질랜드"},
    {value: "NC", text: "뉴칼레도니아"},
    {value: "NU", text: "니우에"},
    {value: "NE", text: "니제르"},
    {value: "NI", text: "니카라과"},
    {value: "TW", text: "대만"},
    {value: "KR", text: "대한민국"},
    {value: "DK", text: "덴마크"},
    {value: "DO", text: "도미니카공화국"},
    {value: "DE", text: "독일"},
    {value: "LR", text: "라이베리아"},
    {value: "LV", text: "라트비아"},
    {value: "RU", text: "러시아 연방"},
    {value: "LB", text: "레바논"},
    {value: "LS", text: "레소토"},
    {value: "RO", text: "루마니아"},
    {value: "LU", text: "룩셈부르크"},
    {value: "RW", text: "르완다"},
    {value: "LY", text: "리비아"},
    {value: "LT", text: "리투아니아"},
    {value: "LI", text: "리히텐슈타인"},
    {value: "MG", text: "마다가스카르"},
    {value: "MH", text: "마셜군도"},
    {value: "MO", text: "마카오"},
    {value: "MK", text: "마케도니아"},
    {value: "MW", text: "말라위"},
    {value: "MY", text: "말레이시아"},
    {value: "ML", text: "말리"},
    {value: "MX", text: "멕시코"},
    {value: "MC", text: "모나코"},
    {value: "MA", text: "모로코"},
    {value: "MU", text: "모리셔스"},
    {value: "MR", text: "모리타니"},
    {value: "MZ", text: "모잠비크"},
    {value: "ME", text: "몬테네그로"},
    {value: "MS", text: "몬트세라트"},
    {value: "MD", text: "몰도바"},
    {value: "MV", text: "몰디브"},
    {value: "MT", text: "몰타"},
    {value: "MN", text: "몽골"},
    {value: "US", text: "미국"},
    {value: "MM", text: "미얀마"},
    {value: "FM", text: "미크로네시아"},
    {value: "VU", text: "바누아투"},
    {value: "BH", text: "바레인"},
    {value: "BB", text: "바베이도스"},
    {value: "VA", text: "바티칸시국"},
    {value: "BS", text: "바하마"},
    {value: "BD", text: "방글라데시"},
    {value: "BM", text: "버뮤다"},
    {value: "BJ", text: "베냉"},
    {value: "VE", text: "베네수엘라"},
    {value: "VN", text: "베트남"},
    {value: "BE", text: "벨기에"},
    {value: "BY", text: "벨라루스"},
    {value: "BZ", text: "벨리즈"},
    {value: "BA", text: "보스니아헤르체고비나"},
    {value: "BW", text: "보츠와나"},
    {value: "BO", text: "볼리비아"},
    {value: "BI", text: "부룬디"},
    {value: "BF", text: "부르키나 파소"},
    {value: "BT", text: "부탄"},
    {value: "KP", text: "북한"},
    {value: "BG", text: "불가리아"},
    {value: "BR", text: "브라질"},
    {value: "BN", text: "브루나이"},
    {value: "SA", text: "사우디아라비아"},
    {value: "SM", text: "산마리노"},
    {value: "EH", text: "서사하라"},
    {value: "SN", text: "세네갈"},
    {value: "RS", text: "세르비아"},
    {value: "SC", text: "세이셸"},
    {value: "LC", text: "세인트루시아"},
    {value: "SO", text: "소말리아"},
    {value: "SB", text: "솔로몬제도"},
    {value: "SD", text: "수단"},
    {value: "SR", text: "수리남"},
    {value: "LK", text: "스리랑카"},
    {value: "SZ", text: "스와질란드"},
    {value: "SE", text: "스웨덴"},
    {value: "CH", text: "스위스"},
    {value: "ES", text: "스페인"},
    {value: "SK", text: "슬로바키아"},
    {value: "SI", text: "슬로베니아"},
    {value: "SY", text: "시리아"},
    {value: "SL", text: "시에라 리온"},
    {value: "SG", text: "싱가포르"},
    {value: "AE", text: "아랍에미리트"},
    {value: "AM", text: "아르메니아"},
    {value: "AR", text: "아르헨티나"},
    {value: "IS", text: "아이슬란드"},
    {value: "HT", text: "아이티"},
    {value: "IE", text: "아일랜드"},
    {value: "AZ", text: "아제르바이잔"},
    {value: "AF", text: "아프가니스탄"},
    {value: "AD", text: "안도라"},
    {value: "AL", text: "알바니아"},
    {value: "DZ", text: "알제리"},
    {value: "AO", text: "앙골라"},
    {value: "AG", text: "앤티가바부다"},
    {value: "ER", text: "에리트리아"},
    {value: "EE", text: "에스토니아"},
    {value: "EC", text: "에콰도르"},
    {value: "ET", text: "에티오피아"},
    {value: "GB", text: "영국"},
    {value: "YE", text: "예멘"},
    {value: "OM", text: "오만"},
    {value: "AT", text: "오스트리아"},
    {value: "HN", text: "온두라스"},
    {value: "JO", text: "요르단"},
    {value: "UG", text: "우간다"},
    {value: "UY", text: "우루과이"},
    {value: "UZ", text: "우즈베키스탄"},
    {value: "UA", text: "우크라이나"},
    {value: "IQ", text: "이라크"},
    {value: "IR", text: "이란"},
    {value: "IL", text: "이스라엘"},
    {value: "EG", text: "이집트"},
    {value: "IT", text: "이탈리아"},
    {value: "IN", text: "인도"},
    {value: "ID", text: "인도네시아"},
    {value: "JP", text: "일본"},
    {value: "JM", text: "자메이카"},
    {value: "ZM", text: "잠비아"},
    {value: "GQ", text: "적도기니"},
    {value: "GE", text: "조지아"},
    {value: "CN", text: "중국"},
    {value: "CF", text: "중앙아프리카공화국"},
    {value: "DJ", text: "지부티"},
    {value: "TD", text: "챠드"},
    {value: "CZ", text: "체코"},
    {value: "CL", text: "칠레"},
    {value: "CM", text: "카메룬"},
    {value: "CV", text: "카보베르데"},
    {value: "KZ", text: "카자흐스탄"},
    {value: "QA", text: "카타르"},
    {value: "KH", text: "캄보디아"},
    {value: "CA", text: "캐나다"},
    {value: "KE", text: "케냐"},
    {value: "KM", text: "코모로"},
    {value: "CR", text: "코스타리카"},
    {value: "CI", text: "코트디부아르"},
    {value: "CO", text: "콜롬비아"},
    {value: "CG", text: "콩고"},
    {value: "CD", text: "콩고민주공화국"},
    {value: "CU", text: "쿠바"},
    {value: "KW", text: "쿠웨이트"},
    {value: "HR", text: "크로아티아"},
    {value: "KG", text: "키르기스스탄"},
    {value: "KI", text: "키리바시"},
    {value: "CY", text: "키프로스"},
    {value: "TJ", text: "타지키스탄"},
    {value: "TZ", text: "탄자니아"},
    {value: "TH", text: "태국"},
    {value: "TR", text: "터키"},
    {value: "TG", text: "토고"},
    {value: "TO", text: "통가"},
    {value: "TM", text: "투르크메니스탄"},
    {value: "TV", text: "투발루"},
    {value: "TN", text: "튀니지"},
    {value: "TT", text: "트리니다드토바고"},
    {value: "PA", text: "파나마"},
    {value: "PY", text: "파라과이"},
    {value: "PK", text: "파키스탄"},
    {value: "PG", text: "파푸아 뉴기니"},
    {value: "PW", text: "팔라우"},
    {value: "PS", text: "팔레스타인"},
    {value: "PE", text: "페루"},
    {value: "PT", text: "포르투갈"},
    {value: "PL", text: "폴란드"},
    {value: "PR", text: "푸에르토 리코"},
    {value: "FR", text: "프랑스"},
    {value: "FJ", text: "피지"},
    {value: "FI", text: "핀란드"},
    {value: "PH", text: "필리핀"},
    {value: "HU", text: "헝가리"},
    {value: "AU", text: "호주"},
    {value: "HK", text: "홍콩"}
  ]

  // 종합계좌 생성 [1010]
  TSDataSet.defaultDsList1010 = { // //종합계좌번호 콤보화면(951590 디비전에) search 후 담길 리턴 dataSet
    csId: '',
    cno: '',
    cnm: '',
    rcnoKindTc: '',
    rcno: '',
    cono: '',
    genTaxtTc: '',
    persnBusiMnBsno: '',
    corpYn: '',
    csIvtdRatingC: '',
    procDt: '',
    valdTrmYys: '',
    ques10: '',
    tacno: '',
    qtnSeq: '',
    age: '',
    tacnoBnkbSeq: '',
    regDate: '',
    tassIssncAccYn: '',
  }

  // 종합계좌 생성 [1010]
  TSDataSet.defaultDsDetail1010 = { //종합계좌번호 콤보화면(951590 디비전에) search 후 담길 리턴 dataSet
    tacno: '',
    bnkbSeq: '',
    lastChngDt: '',
    lastChngMnTmno: '',
    lastChngMnUsid: '',
    opngBrcd: '',
    opngDate: '',
    bnkbIssncNmts: '',
    bnkbPswd1: '',
    bnkbPswd2: '',
    msEcrtn: '',
    bnkbStatC: '',
    issncBrcd: '',
    isueDate: '',
    nhvSealYn: '',
    lastAccSeq: '',
    lastUsePage: '',
    csId: '',
    cnm: '',
    fxpdIssncUsCntn: '',
    fxpdEstmBaseMethC: '',
    fxpdIsueNos: '',
    fxpdPrfYn: '',
    ftrmNhvBalYn: '',
    fxpdPrfIssncDd: '',
    admkNm: '',
    corpYn: '',
    gdC: '',
    pswdCheckYn: '',
    invtMnEmno: '',
    mngrEmno: '',
    fnlDsgnEmno: '',
    passBookBnkbIssncYn: '',
    bnkbIssncYn: '',
    vtAcnoYn: '',
    tassIssncAccYn: '',
    trstVtAcno: '',
  }

  
  /**
   * 대리인 등록 팝업 결과 저장
   * 팝업에서 전체 내용을 가져옴. 기존 MSPTS100M 에서 ds_detail01 을 dataSet으로 사용
   * 전자서식에서 더 필요한 내용이 있어서 신규 추가
   */
  TSDataSet.ds_deputyObj = { 
    agntCsId: '',                     // 대리인고객ID
    prhsRel: '',                      // 본인관계
    accOpngAthzYn: '',                // 계좌개설위임여부
    appnIndcAthzYn: '',
    bktrStipAthzYn: '',               // 운용지시위임여부
    accFtrnStipAthzYn: '',            // 은행이체약정위임여부
    trstContAthzYn: '',               // 신탁계약위임여부
    trstContChngAthzYn: '',           // 신탁계약변경위임여부
    trstContTmtlAthzYn: '',           // 신탁계약해지위임여부
    etcYn: '',                        // 기타여부
    etcExpl: '',                      // 기타설명
    cnm: '',                          // 대리인고객명 
    agntNm: '',                       // 대리인고객명 
    chk_AgntDelYn: '',                // 대리인삭제여부
    rcnoKindTc: '',                   // 실명번호 코드값
    rcno: '',                         // 실명번호
    agntBirthMmdd: '',                // 생년월일
    mvphBusiMnNo: '',                 // 전화번호1
    mvphPofn: '',                     // 전화번호2
    mvphSeq: '',                      // 전화번호3
    homeRmnAddr: '',                  // 주소
  }

 export default TSDataSet
// eslint-disable-next-line